import React, { useEffect, useState, useRef } from "react";
import {
  Box,
  Divider,
  InputBase,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import IconButton from "@mui/material/IconButton";
import StarIcon from "@mui/icons-material/Star";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import { isValidRegex } from "../../../app-old/utilities/utils";
import { debounce } from "lodash";
import { SUPER_ADMIN_ROLE } from "../../Constants";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Routes } from "../../Utilities";

const styles = {
  list_style: {
    width: "300px !important",
    position: "absolute",
    overflow: "auto",
    backgroundColor: "#FFFFFF !important",
    boxShadow:
      "0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.20) !important",
    borderRadius: "4px",
    top: "48px",
    right: "21px",
    color: "rgba(0,0,0,0.60)",
    paddingBottom: "4px",
    "& .MuiSvgIcon-root": {
      margin: "0px 12px 0px 20px",
    },
    "& .MuiListItem-root": {
      padding: "0",
      borderRight: "1px solid rgba(0,0,0,0.12)",
    },
    "& .MuiListItemButton-root": {
      padding: "0px 20px !important",
      lineHeight: "40px",
    },
    "& .MuiListItemButton-root:hover": {
      backgroundColor: "#FAFAFA",
    },
    "& .MuiListItemText-root:hover": {
      color: "#263238",
    },
    "& .MuiListItemText-root": {
      color: "rgba(0,0,0,0.60)",
      lineHeight: "40px",
      marginTop: "0px",
      marginBottom: "0px",
    },
    paddingTop: "0px !important",
    "& .MuiTypography-root": {
      lineHeight: "40px",
      width: "230px",
      textOverflow: "ellipsis",
      overflow: "hidden",
      whiteSpace: "nowrap",
    },
    "& .MuiTouchRipple-root": {
      right: "-16px",
      left: "-16px",
    },
    "& .MuiInputBase-root": {
      display: "inline-block",
    },
  },
  inputBase_style: {
    ml: "1px",
    flex: 1,
    lineHeight: "28px",
    input: {
      "&::placeholder": {
        color: "rgba(0,0,0,0.60)",
        fontSize: "16px",
        lineHeight: "28px",
        fontWeight: "400",
        letterSpacing: "0.15px",
      },
    },
  },
  listItemText_style: {
    color: "#ffffff !important",
    backgroundColor: "#4f5b62 !important",
    paddingTop: "5px",
    paddingBottom: "5px",
    paddingLeft: "20px",
    "& .MuiListItemText-root:hover": {
      color: "#ffffff",
    },
  },
  iconButton_style: {
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.1)",
      cursor: "pointer",
    },
    position: "absolute",
    right: "5px",
    padding: "5px",
    margin: "0px !important",
    "& .MuiSvgIcon-root": {
      margin: "0px",
    },
    "& .MuiTouchRipple-root": {
      left: "0px",
      right: "0px",
    },
  },
  starICon_style: {
    color: "rgba(0,0,0,0.54)",
  },
};
function ChangeCompany(props) {
  const {
    companyList,
    favoriteCompanies,
    selectCompany,
    closeMenu,
    defaultCompany,
    starCompanyHandler,
    unStarCompanyHandler,
  } = props;
  const history = useHistory();
  const [companyListData, setCompanyListData] = useState(companyList);
  const [favoriteCompanyList, setFavoriteCompanyList] =
    useState(favoriteCompanies);
  const [search, setNewSearch] = useState("");
  const [selectedCompany, setSelectedCompany] = useState("");
  const searchRef = useRef();

  /* istanbul ignore next */
  const handleSearch = debounce(() => {
    setNewSearch(searchRef.current.value);
  }, 500);

  useEffect(() => {
    /* istanbul ignore if */
    if (defaultCompany) {
      setSelectedCompany(defaultCompany.companyuuid);
    } else {
      setSelectedCompany(null);
    }
    /* istanbul ignore if */
    if (companyList) {
      setCompanyListData(() => companyList);
    }
    /* istanbul ignore next */
    if (favoriteCompanyList) {
      setFavoriteCompanyList(() => favoriteCompanies);
    }
  }, []);
  useEffect(() => {
    /* istanbul ignore if */
    if (
      companyListData &&
      companyListData.length === 0 &&
      search.length === 0
    ) {
      setCompanyListData(() => companyList);
    }
  });

  useEffect(() => {
    /* istanbul ignore if */
    if (favoriteCompanyList && search.length === 0) {
      setFavoriteCompanyList(() => favoriteCompanies);
    }
  }, [favoriteCompanies]);

  useEffect(() => {
    let favoriteFilter = [];
    let allCompanies = [];
    /* istanbul ignore if */
    if (!isValidRegex(search)) {
      return false;
    }
    /* istanbul ignore if */
    if (search.length > 0) {
      favoriteFilter = favoriteCompanies.filter((item) => {
        return new RegExp(search, "i").exec(item.company_name);
      });
      allCompanies = companyList.filter((item) => {
        return new RegExp(search, "i").exec(item.company_name);
      });
    } else {
      favoriteFilter = favoriteCompanies;
      allCompanies = companyList;
    }
    setFavoriteCompanyList(favoriteFilter);
    setCompanyListData(allCompanies);
  }, [search, favoriteCompanies]);

  const handleSelectedCompany = (value) => {
    setSelectedCompany(value);
  };

  return (
    <ClickAwayListener onClickAway={closeMenu}>
      <List sx={styles.list_style}>
        <Box sx={{ margin: "14px 0 14px" }}>
          <SearchIcon sx={{ color: "rgba(0,0,0,0.54)" }} />
          <InputBase
            sx={styles.inputBase_style}
            inputRef={searchRef}
            placeholder="Search Companies"
            onChange={/* istanbul ignore next */ () => handleSearch()}
          />
        </Box>
        <Divider />
        <Box
          sx={{
            maxHeight: "calc(100vh - 148px)",
            overflowY: "scroll",
            overflowX: "hidden",
            "&::-webkit-scrollbar-thumb": {
              borderRadius: "100px",
              border: "3px solid transparent",
              backgroundClip: "content-box",
              height: "58px",
            },
            "&::-webkit-scrollbar": {
              width: "12px",
            },
            ...(companyListData?.length === 0 && {
              overflowY: "hidden",
            }),
          }}
        >
          {props.roleName === SUPER_ADMIN_ROLE && (
            <ListItem>
              <ListItemButton
                onClick={
                  /* istanbul ignore next */ () => {
                    selectCompany(null);
                    handleSelectedCompany(null);
                  }
                }
              >
                <ListItemText
                  sx={{
                    "& .MuiListItemText-primary": {
                      cursor: "pointer",
                    },
                    ...(selectedCompany === null && {
                      color: "#007AC9 !important",
                    }),
                  }}
                >
                  All Companies
                </ListItemText>
              </ListItemButton>
            </ListItem>
          )}
          {favoriteCompanyList?.length > 0 && (
            <ListItem>
              <ListItemText sx={styles.listItemText_style}>
                Starred
              </ListItemText>
            </ListItem>
          )}
          {favoriteCompanyList
            ?.sort(
              /* istanbul ignore next */ (a, b) =>
                a.company_name.toLowerCase() > b.company_name.toLowerCase()
                  ? 1
                  : -1,
            )
            .map(
              /* istanbul ignore next */ (item, index) => (
                <>
                  <Divider />
                  <ListItem
                    sx={{
                      height: "40px",
                    }}
                    key={index}
                  >
                    <ListItemButton
                      onClick={
                        /* istanbul ignore next */ () => {
                          selectCompany(item);
                          handleSelectedCompany(item.companyuuid);
                        }
                      }
                    >
                      <ListItemText
                        sx={
                          selectedCompany === item.companyuuid
                            ? { color: "#007AC9 !important" }
                            : ""
                        }
                      >
                        {item.company_name}
                      </ListItemText>
                    </ListItemButton>
                    <IconButton
                      onClick={
                        /* istanbul ignore next */ () => {
                          unStarCompanyHandler(item.companyuuid);
                        }
                      }
                      color="primary"
                      sx={styles.iconButton_style}
                      aria-label="favorite"
                      component="span"
                    >
                      <StarIcon sx={styles.starICon_style} />
                    </IconButton>
                  </ListItem>
                </>
              ),
            )}

          <ListItem>
            <ListItemText sx={styles.listItemText_style}>
              Companies
            </ListItemText>
          </ListItem>
          {companyListData
            ?.filter(/* istanbul ignore next */ (value) => !value.exclude)
            .map(
              /* istanbul ignore next */ (list) => (
                <>
                  <Divider />
                  <ListItem sx={{ height: "40px" }} key={list.companyuuid}>
                    <ListItemButton
                      onClick={
                        /* istanbul ignore next */ () => {
                          selectCompany(list);
                          handleSelectedCompany(list.companyuuid);
                        }
                      }
                    >
                      <ListItemText
                        primary={list.company_name}
                        sx={
                          selectedCompany === list.companyuuid
                            ? { color: "#007AC9 !important" }
                            : ""
                        }
                      />
                    </ListItemButton>
                    {props.roleName === SUPER_ADMIN_ROLE && (
                      <IconButton
                        color="primary"
                        onClick={
                          favoriteCompanies.some(
                            /* istanbul ignore next */
                            (item) => item.companyuuid === list.companyuuid,
                          )
                            ? /* istanbul ignore next */ () =>
                                unStarCompanyHandler(list.companyuuid)
                            : /* istanbul ignore next */ () =>
                                starCompanyHandler(list)
                        }
                        sx={styles.iconButton_style}
                        aria-label="favorite"
                        component="span"
                      >
                        {favoriteCompanies.some(
                          /* istanbul ignore next */
                          (item) => item.companyuuid === list.companyuuid,
                        ) ? (
                          <StarIcon sx={styles.starICon_style} />
                        ) : (
                          <StarBorderIcon sx={styles.starICon_style} />
                        )}
                      </IconButton>
                    )}
                  </ListItem>
                </>
              ),
            )}
        </Box>
      </List>
    </ClickAwayListener>
  );
}
export default ChangeCompany;
