import { Box, Divider, Skeleton } from "@mui/material";
import React from "react";
import useMediaQuery from "@mui/material/useMediaQuery";

function SkeletonPageHeader() {
  const responsive = useMediaQuery("(max-width:650px)");
  /* istanbul ignore next */
  return (
    <Box
      sx={{
        height: "100vh",
        backgroundColor: "#f7f8f9",
        display: "table",
        width: "100%",
      }}
    >
      <Box
        sx={{
          p: "0 30px",
          height: "50px",
          backgroundColor: "#ffffff",
          borderBottom: "1px solid #e9e9e9",
          display: "flex",
          alignItems: "center",
          position: "fixed",
          width: "100%",
        }}
      >
        <Skeleton variant="rectangular" width="100px" height="18px" />
      </Box>
      <Box
        sx={{
          maxWidth: "660px",
          width: "100%",
          display: "flex",
          alignItems: "center",
          margin: "auto",
          height: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            backgroundColor: "initial",
            borderRadius: "0",
            // ...(responsive && {
            //   flexDirection: "column",
            //   justifyContent: "center !important",
            // }),
            justifyContent: "space-between",
            minHeight: "calc(100vh - 280px)",
            p: "40px 0",
            width: "100%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              justifyContent: "center",
              //   ...(responsive && { mb: "30px" }),
              maxWidth: "280px",
            }}
          >
            <Box>
              <Skeleton variant="rectangular" height="124px" width="224px" />
            </Box>
            {/* {!responsive && (
              <> */}
            <Divider sx={{ width: "100%", mt: "15px", mb: "15px" }} />
            <Box>
              <Skeleton
                variant="rectangular"
                height="15px"
                width="225px"
                sx={{ mb: "5px" }}
              />
            </Box>
            <Skeleton
              variant="rectangular"
              height="15px"
              width="250px"
              sx={{ mb: "5px" }}
            />
            <Skeleton
              variant="rectangular"
              height="15px"
              width="160px"
              sx={{ mb: "5px" }}
            />
            {/* </>
            )} */}
          </Box>
          <Box
            sx={{
              borderRadius: "4px",
              height: "100%",
              p: "20px",
              textAlign: "center",
            }}
          >
            <Skeleton variant="rectangular" width="350px" height="354px" />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default SkeletonPageHeader;
