import { Box, Skeleton } from "@mui/material";
import React from "react";

const styles = {
  subheader_box: {
    backgroundColor: "#ffffff",
    width: "100%",
    pt: "70px",
    pl: "24px",
    pr: "32px",
    borderBottom: "1px solid rgba(0,0,0,0.11)",
  },
};

function SkeletonConfigSection() {
  return (
    <Box>
      <Box sx={styles.subheader_box}>
        <Box sx={{ display: "flex" }}>
          <Skeleton variant="text" width="20px" />
          <Skeleton variant="text" width="225px" sx={{ ml: "10px" }} />
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            width: "auto",
            alignItems: "center",
            mb: "20px",
            mt: "15px",
          }}
        >
          <Skeleton
            variant="circular"
            width="80px"
            height="80px"
            sx={{ mr: "30px" }}
          />{" "}
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Skeleton variant="text" width="180px" height="34px" />{" "}
            <Skeleton variant="text" width="180px" height="34px" />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default SkeletonConfigSection;
