// Action Creator
export const types = {
  PROJECT_LIST: "[Project] Project List",
  PROJECT_LIST_SUCCESS: "[Project] Project Success",
  PROJECT_LIST_FAILED: "[Project] Project Failed",
  CREATE_PROJECT: "[Project] Create Project",
  CREATE_PROJECT_SUCCESS: "[Project] Create Project Success",
  CREATE_PROJECT_FAILED: "[Project] Create Project Failed",
  CLEAR_PROJECT: "[Project] Clear Project Data",
  FETCH_A_PROJECT: "[Project] Fetch a Project",
  FETCH_A_PROJECT_SUCCESS: "[Project] Fetch a Project Success",
  FETCH_A_PROJECT_FAILED: "[Project] Fetch a Project Failed",
  CLEAR_A_PROJECT: "[Project] Clear a Project Data",
  UPDATE_PROJECT: "[Project] Update Project",
  UPDATE_PROJECT_SUCESS: "[Project] Update Project Success",
  UPDATE_PROJECT_FAILED: "[Project] Update Project Failed",
  FETCH_A_PROJECT_STATS: "[Project] Fetch a Project Stats",
  FETCH_A_PROJECT_STATS_SUCCESS: "[Project] Fetch a Project Stats Success",
  FETCH_A_PROJECT_STATS_FAILED: "[Project] Fetch a Project Stats Failed",
  FETCH_A_PROJECT_STATS_CURRENT_MONTH:
    "[Project] Fetch a Project Stats Current Month",
  FETCH_A_PROJECT_STATS_CURRENT_MONTH_SUCCESS:
    "[Project] Fetch a Project Stats Current Month Success",
  FETCH_A_PROJECT_STATS_CURRENT_MONTH_FAILED:
    "[Project] Fetch a Project Stats Current Month Failed",
  FETCH_A_PROJECT_STATS_BILLING_USAGE:
    "[Project] Fetch a Project Stats Billing Usage",
  FETCH_A_PROJECT_STATS_BILLING_USAGE_SUCCESS:
    "[Project] Fetch a Project Stats Billing Usage Success",
  FETCH_A_PROJECT_STATS_BILLING_USAGE_FAILED:
    "[Project] Fetch a Project Stats Billing Usage Failed",
};

export const fetchProjectList = (companyId, size, query) => ({
  type: types.PROJECT_LIST,
  payload: {
    companyId,
    size,
    query,
  },
});
export const FetchProjectListSuccess = (list) => ({
  type: types.PROJECT_LIST_SUCCESS,
  payload: list,
});
export const FetchProjectListFailed = (error) => ({
  type: types.PROJECT_LIST_FAILED,
  payload: error,
});
export const CreateProject = (projectDetails) => ({
  type: types.CREATE_PROJECT,
  payload: projectDetails,
});
export const CreateProjectSuccess = (response) => ({
  type: types.CREATE_PROJECT_SUCCESS,
  payload: response,
});
export const CreateProjectFailed = (error) => ({
  type: types.CREATE_PROJECT_FAILED,
  payload: error,
});
export const ClearProjectDetails = () => ({
  type: types.CLEAR_PROJECT,
});
export const FetchAProject = (id) => ({
  type: types.FETCH_A_PROJECT,
  payload: id,
});
export const FetchAProjectSuccess = (response) => ({
  type: types.FETCH_A_PROJECT_SUCCESS,
  payload: response,
});
export const FetchAProjectFailed = (error) => ({
  type: types.FETCH_A_PROJECT_FAILED,
  payload: error,
});
export const UpdateProject = (projectDetails) => ({
  type: types.UPDATE_PROJECT,
  payload: projectDetails,
});
export const UpdateProjectSuccess = (response) => ({
  type: types.UPDATE_PROJECT_SUCESS,
  payload: response,
});
export const UpdateProjectFailed = (error) => ({
  type: types.UPDATE_PROJECT_FAILED,
  payload: error,
});
export const ClearAProject = () => ({
  type: types.CLEAR_A_PROJECT,
});
export const FetchAProjectStats = (data) => ({
  type: types.FETCH_A_PROJECT_STATS,
  payload: data,
});
export const FetchAProjectStatsSuccess = (response) => ({
  type: types.FETCH_A_PROJECT_STATS_SUCCESS,
  payload: response,
});
export const FetchAProjectStatsFailed = (error) => ({
  type: types.FETCH_A_PROJECT_STATS_FAILED,
  payload: error,
});
export const FetchAProjectStatsCurrentMonth = (data) => ({
  type: types.FETCH_A_PROJECT_STATS_CURRENT_MONTH,
  payload: data,
});
export const FetchAProjectStatsCurrentMonthSuccess = (response) => ({
  type: types.FETCH_A_PROJECT_STATS_CURRENT_MONTH_SUCCESS,
  payload: response,
});
export const FetchAProjectStatsCurrentMonthFailed = (error) => ({
  type: types.FETCH_A_PROJECT_STATS_CURRENT_MONTH_FAILED,
  payload: error,
});
export const FetchAProjectStatsBillingUsage = (data) => ({
  type: types.FETCH_A_PROJECT_STATS_BILLING_USAGE,
  payload: data,
});
export const FetchAProjectStatsBillingUsageSuccess = (response) => ({
  type: types.FETCH_A_PROJECT_STATS_BILLING_USAGE_SUCCESS,
  payload: response,
});
export const FetchAProjectStatsBillingUsageFailed = (error) => ({
  type: types.FETCH_A_PROJECT_STATS_BILLING_USAGE_FAILED,
  payload: error,
});
