import { Box, Skeleton } from "@mui/material";
import React from "react";

function SkeletonLandingProject() {
  return (
    <Box
      sx={{
        maxWidth: "575px",
        width: "100%",
        marginLeft: "auto",
        marginRight: "auto",
        paddingTop: "30px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItem: "center",
        }}
      >
        <Box>
          {" "}
          <Skeleton variant="text" width="46px" height="48px" />
        </Box>

        <Box sx={{ ml: "10px" }}>
          {" "}
          <Skeleton variant="text" width="520px" height="48px" />
        </Box>
      </Box>
      <Skeleton variant="text" width="100%" height="48px" />
      <Skeleton variant="text" width="100%" height="95px" />
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <Skeleton variant="text" width="270px" height="50px" />
        <Skeleton variant="text" width="50px" height="50px" />
        <Skeleton variant="text" width="132px" height="50px" />
      </Box>
    </Box>
  );
}

export default SkeletonLandingProject;
