import {
  Box,
  Divider,
  Link,
  ListItemIcon,
  Menu,
  MenuItem,
} from "@mui/material";
import React from "react";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import VpnKeyIcon from "@mui/icons-material/VpnKey";
import ContactSupportIcon from "@mui/icons-material/ContactSupport";
import LogoutIcon from "@mui/icons-material/Logout";
import { routes } from "../../../app-old/utilities/routes";
import { Link as Links } from "react-router-dom";

const styles = {
  menu: {
    mt: 1.5,
    top: "36px !important",
    right: "26px",
    ml: "-10px",
    left: "auto !important",
    minWidth: "250px",
    maxWidth: "400px",
    backgroundColor: "#FFFFFF !important",
    boxShadow:
      "0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.20) !important",
    cursor: "inherit",

    "& .MuiSvgIcon-root": {
      width: 40,
      height: 24,
      ml: "4px",
      mr: "8px",
      mt: "-1px",
    },
    "& .MuiMenuItem-root:hover": {
      backgroundColor: "#FFFFFF",
    },
    "& .MuiDivider-root": {
      marginTop: "5px !important",
      marginBottom: "5px !important",
    },
    "& .MuiListItemIcon-root": {
      fontSize: "16px",
      color: "rgba(0,0,0,0.60) !important",
      letterSpacing: "0.15px",
      lineHeight: "24px",
      fontWeight: 400,
    },

    "& .MuiListItemIcon-root:hover": {
      color: "#263238 !important",
    },
  },
  menuItem: {
    cursor: "default",
    paddingTop: "10px",
    paddingBottom: "10px",
  },
  currentUser_box: {
    fontSize: "16px",
    color: "#007AC9",
    letterSpacing: "0.15px",
    lineHeight: "20px",
    maxWidth: "316px !important",
    marginLeft: "0px !important",
    textOverflow: "ellipsis",
    msTextOverflow: "ellipsis",
    overflow: "hidden",
    fontWeight: 400,
  },
  authUser_box: {
    fontSize: "14px !important",
    color: "#263238",
    letterSpacing: "0.1px",
    lineHeight: "20px",
    maxWidth: "316px !important",
    marginLeft: "0px !important",
    textOverflow: "ellipsis",
    msTextOverflow: "ellipsis",
    overflow: "hidden",
    fontWeight: 500,
  },
  accountCircleIcon: {
    color: "rgba(0,0,0,0.54)",
    width: "40px",
    height: "40px",
  },
};

export default function UserMenu(props) {
  const { menuClick, auth, currentUser, closeMenu } = props;
  const { change_password, logout } = routes;
  return (
    <Box sx={{ display: "flex", alignItems: "center", textAlign: "center" }}>
      <Menu
        open={menuClick}
        onClose={closeMenu}
        onClick={closeMenu}
        PaperProps={{
          elevation: 0,
          sx: styles.menu,
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "top" }}
      >
        <MenuItem sx={styles.menuItem} disableRipple>
          <AccountCircleIcon
            fontSize="medium"
            style={styles.accountCircleIcon}
          />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Box component="span" sx={styles.currentUser_box}>
              {currentUser && currentUser.name}
            </Box>
            <Box sx={styles.authUser_box}>{auth && auth.user.companyname}</Box>
          </Box>
        </MenuItem>
        <Divider />
        <Links to={change_password} style={{ color: "inherit" }}>
          <MenuItem>
            <ListItemIcon>
              <VpnKeyIcon />
              Change Password
            </ListItemIcon>
          </MenuItem>
        </Links>
        <Link
          underline="none"
          target="_blank"
          rel="noopener"
          color="inherit"
          href="https://help.datazoom.io/hc/en-us"
        >
          <MenuItem>
            <ListItemIcon>
              <ContactSupportIcon />
              Help & Support
            </ListItemIcon>
          </MenuItem>
        </Link>
        <Divider />
        <Links to={logout} style={{ color: "inherit" }}>
          <MenuItem>
            <ListItemIcon>
              <LogoutIcon />
              Logout
            </ListItemIcon>
          </MenuItem>
        </Links>
      </Menu>
    </Box>
  );
}
