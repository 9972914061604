import Axios from "axios";
import { baseApiV2 } from "../../constants/Presets";

export function FetchProjectList(payload) {
  const url = `${baseApiV2}project/list?`;
  const params = [];
  const companyId = payload.companyId || "";
  params.push(`companyId=${companyId}`);
  const size = payload.size || 0;
  params.push(`size=${size}`);
  const query = payload.query || "";
  params.push(`query=${query}`);
  const paramStr = params.join("&");
  return Axios.get(url + paramStr);
}
export function CreateProject(payload) {
  const param = {
    name: payload.name,
    companyId: payload.companyId,
  };
  return Axios({
    method: "POST",
    url: `${baseApiV2}project`,
    data: param,
  });
}
export function FetchAProject(id) {
  return Axios({
    method: "get",
    url: `${baseApiV2}project/${id}`,
  });
}
export function UpdateProject(payload) {
  const param = {
    id: payload.id,
    name: payload.name,
  };
  return Axios({
    method: "PATCH",
    url: `${baseApiV2}project`,
    data: param,
  });
}
export function FetchAProjectStats(payload) {
  const url = `${baseApiV2}dataUsage/project?`;
  const params = [];
  const { type } = payload;
  params.push(`agg_type=${type}`);
  const { fromDate } = payload;
  params.push(`from_date=${fromDate}`);
  const { toDate } = payload;
  params.push(`to_date=${toDate}`);
  const projectId = payload.projectId || "";
  params.push(`project_id=${projectId}`);
  const paramStr = params.join("&");
  return Axios.get(url + paramStr);
}
