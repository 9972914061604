import { Box, Divider, Skeleton } from "@mui/material";
import React from "react";

const styles = {
  rightSection: {
    ml: "43px",
    mr: "90px",
    mt: "43px",
    mb: "55px",
  },
  category_box: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    pl: "66px",
    mt: "20px",
    width: "100%",
  },
  box_grid: {
    display: "grid",
    gridGap: "34px",
    gridTemplateColumns: "repeat(auto-fill, minmax(130px, 1fr))",
    p: "24px 120px 30px 75px",
  },
};
function SkeletonPlayerList({ collector }) {
  /* istanbul ignore next */
  return (
    <Box>
      <Box sx={styles.rightSection}>
        {collector && (
          <Skeleton variant="rectangular" width="200px" height="40px" />
        )}
        <Box sx={styles.category_box}>
          <Skeleton
            variant="rectangular"
            width="200px"
            sx={{ pl: "66px", mr: "20px" }}
          />
          <Box sx={{ width: "100%" }}>
            <Divider />
          </Box>
        </Box>
        <Box sx={styles.box_grid}>
          {Array.from(new Array(30)).map(
            /* istanbul ignore next */ (e, i) => (
              <Box
                key={i}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  cursor: "pointer",
                }}
              >
                <Skeleton variant="circular" width="68px" height="68px" />
                <Skeleton
                  variant="text"
                  width="130px"
                  height="40px"
                  sx={{
                    pt: "12px",
                    textAlign: "center",
                  }}
                />
              </Box>
            ),
          )}
        </Box>
      </Box>
    </Box>
  );
}

export default SkeletonPlayerList;
