import { types } from "./actions";

const initialState = {
  list: [],
  error: null,
  loading: false,
  loaded: false,
  isLoading: false,
  isLoaded: false,
  currentCustomData: [],
  currentCustomLoading: false,
  currentCustomLoaded: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case types.FETCH_CUSTOM_DATA: {
      return {
        ...state,
        isLoading: true,
        isLoaded: false,
      };
    }
    case types.FETCH_CUSTOM_DATA_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        list: action.payload,
      };
    }
    case types.FETCH_CUSTOM_DATA_FAILED: {
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        error: action.payload,
      };
    }
    case types.CLEAR_CUSTOM_DATA: {
      return {
        ...initialState,
      };
    }
    case types.FETCH_A_CUSTOM_DATA: {
      return {
        ...state,
        currentCustomLoading: true,
        currentCustomLoaded: false,
      };
    }
    case types.FETCH_A_CUSTOM_DATA_SUCCESS: {
      return {
        ...state,
        currentCustomLoading: false,
        currentCustomLoaded: true,
        currentCustomData: action.payload,
      };
    }
    case types.FETCH_A_CUSTOM_DATA_FAILED: {
      return {
        ...state,
        currentCustomLoading: false,
        currentCustomLoaded: true,
        error: action.payload,
      };
    }
    default:
      return state;
  }
}
