import { Skeleton, Box } from "@mui/material";
import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

const style = {
  root: {
    pb: "42px",
    backgroundColor: "#fafafa",
    "&::-webkit-scrollbar-thumb": {
      borderRadius: "100px",
      border: "3px solid transparent",
      backgroundClip: "content-box",
      height: "58px",
    },
    "&::-webkit-scrollbar": {
      width: "12px",
    },
    "&::-webkit-scrollbar-track-piece": {
      borderLeft: "1px solid rgba(0,0,0,0.12)",
    },
  },
  containerBox: {
    width: "100%",
    minWidth: 650,
  },
};

function SkeletonViewList({ rates, authored }) {
  return (
    <Box>
      <TableContainer
        component={Paper}
        sx={{
          ...(authored && {
            height: "480px",
          }),
        }}
      >
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell sx={{ width: "8%" }}></TableCell>
              {Array.from(new Array(6)).map(
                /* istanbul ignore next */ (header, index) => (
                  <TableCell
                    key={index}
                    sx={{
                      p: "17.6px 10px 16px",
                      width:
                        index === 0 || index === 1 || index === 3 || index === 4
                          ? "10%"
                          : index === 5
                          ? "14%"
                          : "25%",
                    }}
                  >
                    <Skeleton animation="wave" variant="text" />
                  </TableCell>
                ),
              )}
              <TableCell sx={{ width: "8%" }}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Array.from(new Array(100)).map(
              /* istanbul ignore next */ (e, index) => (
                <TableRow key={index}>
                  <TableCell
                    align="left"
                    component="th"
                    scope="row"
                  ></TableCell>

                  {Array.from(new Array(6)).map(
                    /* istanbul ignore next */ (e, index) => (
                      <TableCell
                        key={index}
                        component="th"
                        scope="row"
                        align="center"
                        sx={{
                          p: "15px !important",
                        }}
                      >
                        {index > 1 ? (
                          <Skeleton animation="wave" variant="text" />
                        ) : rates ? (
                          <Skeleton animation="wave" variant="text" />
                        ) : (
                          <Skeleton
                            animation="wave"
                            variant="circular"
                            sx={{
                              ml: "30%",
                              width: index === 0 ? "12px" : "50px",
                              height: index === 0 ? "12px" : "50px",
                            }}
                          />
                        )}
                      </TableCell>
                    ),
                  )}

                  <TableCell
                    align="left"
                    component="th"
                    scope="row"
                  ></TableCell>
                </TableRow>
              ),
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

export default SkeletonViewList;
