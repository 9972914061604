import { AppBar, Box, Divider, Skeleton, Toolbar } from "@mui/material";
import React from "react";

const styles = {
  appbox: {
    width: "100%",
    flexDirection: "column-reverse",
    height: "60px",
    backgroundColor: "#4f5b62",
    boxShadow: "0px 2px 4px rgba(100,100,100,0.5)",
  },
  toolbar: {
    pl: "20px !important",
    pr: "20px !important",
    minHeight: "60px !important",
  },
  divider: {
    backgroundColor: "#ffffff",
    m: "20px 9px",
  },
};
function SkeletonAppBar() {
  /* istanbul ignore next */
  return (
    <AppBar sx={styles.appbox}>
      <Box>
        <Toolbar sx={styles.toolbar}>
          <Box
            sx={{
              paddingRight: "20px",
              filter: "invert(1)",
            }}
          >
            <Skeleton variant="rectangular" width="27px" height="20px" />
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Skeleton
              variant="rectangular"
              width="150px"
              height="20px"
              sx={{ backgroundColor: "#616c72" }}
            />
            <Skeleton
              variant="circular"
              width="20px"
              height="20px"
              sx={{ backgroundColor: "#616c72" }}
            />
          </Box>
        </Toolbar>
      </Box>
    </AppBar>
  );
}

export default SkeletonAppBar;
