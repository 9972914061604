import { Box, Divider, Skeleton } from "@mui/material";
import React from "react";

function SkeletonDetailsSection() {
  /* istanbul ignore next */
  return (
    <Box className="scrollbarContainer">
      <Box sx={{ mr: "50px", mt: "14px" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Skeleton
            variant="text"
            width="250px"
            height="32px"
            sx={{ ml: "21px" }}
          />
          <Skeleton variant="circular" width="24px" height="24px" />
        </Box>
        <Box
          sx={{
            ml: "21px",
          }}
        >
          <Divider variant="inset" sx={{ ml: "0px" }} />
          {Array.from(new Array(5)).map(
            /* istanbul ignore next */ (e, i) => (
              <Box key={i}>
                <Skeleton
                  variant="text"
                  width="200px"
                  height="32px"
                  sx={{ m: "18px 0px 1px 0px" }}
                />

                <Skeleton variant="rectangular" width="100%" height="143px" />
              </Box>
            ),
          )}
        </Box>
      </Box>
    </Box>
  );
}

export default SkeletonDetailsSection;
