/* istanbul ignore next */
import { types } from "./actions";

const initialState = {
  list: [],
  all: [],
  error: null,
  loading: false,
  loaded: true,
  creating: false,
  errorResponse: null,
  togglingStatus: false,
  toggleStatusResponse: null,
  toggleStatusError: null,
};
/* istanbul ignore next */
export default function reducer(state = initialState, action) {
  switch (action.type) {
    /* istanbul ignore next */
    case types.FETCH_API_KEYS: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case types.FETCH_API_KEYS_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        list: action.payload,
      };
    }
    case types.FETCH_API_KEYS_FAILED: {
      return {
        ...state,
        loading: false,
        loaded: true,
        all: action.payload,
      };
    }

    case types.CHANGE_STATUS: {
      return {
        ...state,
        togglingStatus: true,
        toggleStatusResponse: null,
        toggleStatusError: null,
      };
    }
    case types.CHANGE_STATUS_SUCCESS: {
      return {
        ...state,
        togglingStatus: false,
        toggleStatusResponse: action.payload,
        toggleStatusError: null,
      };
    }
    case types.CHANGE_STATUS_FAILED: {
      return {
        ...state,
        togglingStatus: false,
        toggleStatusResponse: null,
        toggleStatusError: action.payload,
      };
    }

    case types.DISABLE_STATUS: {
      return {
        ...state,
        togglingStatus: true,
        toggleStatusResponse: null,
        toggleStatusError: null,
      };
    }
    case types.DISABLE_STATUS_SUCCESS: {
      return {
        ...state,
        togglingStatus: false,
        toggleStatusResponse: action.payload,
        toggleStatusError: null,
      };
    }
    case types.DISABLE_STATUS_FAILED: {
      return {
        ...state,
        togglingStatus: false,
        toggleStatusResponse: null,
        toggleStatusError: action.payload,
      };
    }

    case types.CREATE_API_KEY: {
      return {
        ...state,
        creating: true,
      };
    }
    case types.CREATE_API_KEY_SUCCESS: {
      return {
        ...state,
        creating: false,
      };
    }
    case types.CREATE_API_KEY_FAILED: {
      return {
        ...state,
        creating: false,
        errorResponse: action.payload,
      };
    }
    default:
      return state;
  }
}
