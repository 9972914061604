export const types = {
  FETCH_CUSTOM_DATA: "[CustomData] Fetch Custom Data",
  FETCH_CUSTOM_DATA_SUCCESS: "[CustomData] Fetch Custom Data success",
  FETCH_CUSTOM_DATA_FAILED: "[CustomData] Fetch Custom Data failed",
  CLEAR_CUSTOM_DATA: "[CustomData] Clear Custom Data",
  FETCH_A_CUSTOM_DATA: "[CustomData] Fetch A Custom Data",
  FETCH_A_CUSTOM_DATA_SUCCESS: "[CustomData] Fetch A Custom Data success",
  FETCH_A_CUSTOM_DATA_FAILED: "[CustomData] Fetch A Custom Data failed",
};

export const FetchCustomData = (
  companyId,
  collectorConfig,
  collectorType,
  customItemType,
  states,
  status,
  size,
) => ({
  type: types.FETCH_CUSTOM_DATA,
  payload: {
    companyId,
    collectorConfig,
    collectorType,
    customItemType,
    states,
    status,
    size,
  },
});
export const FetchCustomDataSuccess = (list) => ({
  type: types.FETCH_CUSTOM_DATA_SUCCESS,
  payload: list,
});
export const FetchCustomDataFailed = (error) => ({
  type: types.FETCH_CUSTOM_DATA_FAILED,
  payload: error,
});
export const ClearCustomData = () => ({
  type: types.CLEAR_CUSTOM_DATA,
});
export const FetchACustomData = (customDataId) => ({
  type: types.FETCH_A_CUSTOM_DATA,
  payload: customDataId,
});
export const FetchACustomDataSuccess = (list) => ({
  type: types.FETCH_A_CUSTOM_DATA_SUCCESS,
  payload: list,
});
export const FetchACustomDataFailed = (error) => ({
  type: types.FETCH_A_CUSTOM_DATA_FAILED,
  payload: error,
});
