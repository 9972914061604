import { useLocation, useHistory } from "react-router-dom";

import React from "react";

function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    const location = useLocation();
    const history = useHistory();
    return <Component {...props} router={{ location, history }} />;
  }

  return ComponentWithRouterProp;
}

export default withRouter;
