import { all, call, put, takeLatest, takeLeading } from "redux-saga/effects";
import * as dataDictionaryActions from "./actions";
import {
  CreateDataDictionary,
  CreateDataPointJiraTicket,
  FetchADataPoint,
  FetchADataPointsLogs,
  FetchDataDictionaryList,
  FetchDataDictionaryVersion,
  UpdateDatapointManagement,
  FetchDatapointManagementList,
} from "./services";

export function* fetchDataDictionaryListSaga(action) {
  try {
    const response = yield call(FetchDataDictionaryList, action.payload);
    const dataDictionary = response.data !== null ? response.data : [];
    yield put(
      dataDictionaryActions.FetchDataDictionaryListSuccess(dataDictionary),
    );
  } catch (error) {
    yield put(dataDictionaryActions.FetchDataDictionaryListFailed(error));
  }
}
export function* fetchADataPointSaga(action) {
  try {
    const response = yield call(FetchADataPoint, action.payload);
    if (response.data && response.data.success) {
      yield put(
        dataDictionaryActions.FetchADataPointSuccess(response.data.data.result),
      );
    }
  } catch (error) {
    yield put(dataDictionaryActions.FetchADataPointFailed(error));
  }
}
export function* fetchDataDictionaryVersionSaga(action) {
  try {
    const response = yield call(FetchDataDictionaryVersion, action.payload);
    if (response.data && response.data.success) {
      yield put(
        dataDictionaryActions.FetchDataDictionaryVersionSuccess(
          response.data.data.result,
        ),
      );
    }
  } catch (error) {
    yield put(dataDictionaryActions.FetchDataDictionaryVersionFailed(error));
  }
}
export function* createDataDictionarySaga(action) {
  try {
    const response = yield call(CreateDataDictionary, action.payload);
    if (response && response.data && response.data.success) {
      yield put(
        dataDictionaryActions.CreateDataDictionarySuccess(response.data),
      );
    } else {
      yield put(
        dataDictionaryActions.CreateDataDictionaryFailed(response.data),
      );
    }
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put(dataDictionaryActions.CreateDataDictionaryFailed(error));
  }
}

export function* fetchADataPointLogsSaga(action) {
  try {
    const response = yield call(FetchADataPointsLogs, action.payload);
    if (response && response.data && response.data.success) {
      yield put(
        dataDictionaryActions.FetchADataPointLogsSuccess(response.data),
      );
    } else {
      yield put(dataDictionaryActions.FetchADataPointLogsFailed(response.data));
    }
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put(dataDictionaryActions.FetchADataPointLogsFailed(error));
  }
}
export function* fetchDataPointManagementListSaga(action) {
  try {
    const response = yield call(FetchDatapointManagementList, action.payload);
    if (response && response.data && response.data.success) {
      yield put(
        dataDictionaryActions.FetchDatapointManagementListSuccess(
          response.data,
        ),
      );
    } else {
      yield put(
        dataDictionaryActions.FetchDatapointManagementListFailed(response.data),
      );
    }
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put(dataDictionaryActions.FetchDatapointManagementListFailed(error));
  }
}
export function* fetchAuthoredListSaga(action) {
  try {
    const response = yield call(FetchDatapointManagementList, action.payload);
    if (response && response.data && response.data.success) {
      yield put(dataDictionaryActions.FetchAuthoredListSuccess(response.data));
    } else {
      yield put(dataDictionaryActions.FetchAuthoredListFailed(response.data));
    }
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put(dataDictionaryActions.FetchDatapointManagementListFailed(error));
  }
}
export function* updateDatapointManagmentSaga(action) {
  try {
    const response = yield call(UpdateDatapointManagement, action.payload);
    if (response && response.data && response.data.success) {
      yield put(
        dataDictionaryActions.UpdatDataPointManagementSuccess(response.data),
      );
    } else {
      yield put(
        dataDictionaryActions.UpdatDataPointManagementFailed(response.data),
      );
    }
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put(dataDictionaryActions.UpdatDataPointManagementFailed(error));
  }
}
export function* CreateDataPointJiraTicketSaga(action) {
  try {
    const response = yield call(CreateDataPointJiraTicket, action.payload);
    if (response && response.data && response.data.success) {
      yield put(
        dataDictionaryActions.CreateDataPointJiraTicketSuccess(response.data),
      );
    } else {
      yield put(
        dataDictionaryActions.CreateDataPointJiraTicketFailed(response.data),
      );
    }
  } catch (error) {
    // dispatch a failure action to the store with the error
    const message =
      error.response && error.response.data && error.response.data.message
        ? error.response.data.message
        : error;
    yield put(dataDictionaryActions.CreateDataPointJiraTicketFailed(message));
  }
}
export function* dataDictionaryWatcher() {
  yield all([
    takeLatest(
      dataDictionaryActions.types.DATA_DICTIONARY_LIST,
      fetchDataDictionaryListSaga,
    ),
    takeLatest(
      dataDictionaryActions.types.FETCH_A_DATA_DICTIONARY,
      fetchADataPointSaga,
    ),
    takeLatest(
      dataDictionaryActions.types.FETCH_DATA_DICTIONARY_VERSION,
      fetchDataDictionaryVersionSaga,
    ),
    takeLeading(
      dataDictionaryActions.types.CREATE_DATA_DICTIONARY,
      createDataDictionarySaga,
    ),
    takeLatest(
      dataDictionaryActions.types.FETCH_A_DATA_POINT_LOGS,
      fetchADataPointLogsSaga,
    ),
    takeLatest(
      dataDictionaryActions.types.UPDATE_DATAPOINT_MANAGEMENT,
      updateDatapointManagmentSaga,
    ),
    takeLatest(
      dataDictionaryActions.types.FETCH_DATA_POINT_MANAGEMENT_LIST,
      fetchDataPointManagementListSaga,
    ),
    takeLatest(
      dataDictionaryActions.types.FETCH_AUTHORED_LIST,
      fetchAuthoredListSaga,
    ),
    takeLatest(
      dataDictionaryActions.types.CREATE_DATAPOINT_JIRA_TICKET,
      CreateDataPointJiraTicketSaga,
    ),
  ]);
}
