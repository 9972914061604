import Axios from "axios";
import { baseApi } from "../../constants/Presets";
import { getCustomParam } from "./helper";

export function FetchCustomDataList(payload) {
  const url = `${baseApi}v1/customData/list?`;
  const params = [];
  const filters = encodeURIComponent(JSON.stringify(getCustomParam(payload)));
  params.push(`filters=${filters}`);
  const size = 1000;
  params.push(`size=${size}`);
  const paramStr = params.join("&");
  return Axios.get(url + paramStr);
}
export function FetchACustomData(payload) {
  const url = `${baseApi}v1/customData?id=${payload}`;
  return Axios.get(url);
}
