import { all, call, put, takeLatest } from "redux-saga/effects";
import * as customDataActions from "./actions";
import { FetchCustomDataList, FetchACustomData } from "./services";

export function* fetchCustomDataListSaga(action) {
  try {
    const response = yield call(FetchCustomDataList, action.payload);
    const customData =
      response && response.data && response.data.data ? response.data.data : [];
    yield put(customDataActions.FetchCustomDataSuccess(customData));
  } catch (error) {
    yield put(customDataActions.FetchCustomDataFailed(error));
  }
}
export function* fetchACustomDataSaga(action) {
  try {
    const response = yield call(FetchACustomData, action.payload);
    const customData =
      response && response.data && response.data.data ? response.data.data : [];
    yield put(customDataActions.FetchACustomDataSuccess(customData));
  } catch (error) {
    yield put(customDataActions.FetchACustomDataFailed(error));
  }
}
export function* customDataWatcher() {
  yield all([
    takeLatest(
      customDataActions.types.FETCH_CUSTOM_DATA,
      fetchCustomDataListSaga,
    ),
    takeLatest(
      customDataActions.types.FETCH_A_CUSTOM_DATA,
      fetchACustomDataSaga,
    ),
  ]);
}
