import { types } from "./actions";

const initialState = {
  list: [],
  error: null,
  loading: false,
  loaded: false,
  isLoading: false,
  isLoaded: false,
  successResponse: null,
  currentProject: null,
  errorCurrentProject: null,
  projectStats: null,
  projectStatsLoading: false,
  projectStatsLoaded: false,
  currentMonthProjectStats: null,
  billingPeriodUsage: null,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case types.PROJECT_LIST: {
      return {
        ...state,
        isLoading: true,
        isLoaded: false,
      };
    }
    case types.PROJECT_LIST_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        list: action.payload,
      };
    }
    case types.PROJECT_LIST_FAILED: {
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        error: action.payload,
      };
    }
    case types.CREATE_PROJECT: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: null,
        successResponse: null,
      };
    }
    case types.CREATE_PROJECT_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: null,
        successResponse: action.payload,
      };
    }
    case types.CREATE_PROJECT_FAILED: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: action.payload,
      };
    }
    case types.CLEAR_PROJECT: {
      return {
        ...initialState,
      };
    }
    case types.FETCH_A_PROJECT: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case types.FETCH_A_PROJECT_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        errorCurrentProject: null,
        currentProject: action.payload,
      };
    }
    case types.FETCH_A_PROJECT_FAILED: {
      return {
        ...state,
        loading: false,
        loaded: true,
        errorCurrentProject: action.payload,
      };
    }
    case types.CLEAR_A_PROJECT: {
      return {
        ...state,
        loading: false,
        loaded: false,
        currentProject: null,
        errorCurrentProject: null,
      };
    }
    case types.UPDATE_PROJECT: {
      return {
        ...state,
        isLoading: true,
        isLoaded: false,
        error: null,
        successResponse: null,
      };
    }
    case types.UPDATE_PROJECT_SUCESS: {
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        error: null,
        successResponse: action.payload,
      };
    }
    case types.UPDATE_PROJECT_FAILED: {
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        error: action.payload,
      };
    }
    case types.FETCH_A_PROJECT_STATS: {
      return {
        ...state,
        projectStatsLoading: true,
        projectStatsLoaded: false,
      };
    }
    case types.FETCH_A_PROJECT_STATS_SUCCESS: {
      return {
        ...state,
        projectStatsLoading: false,
        projectStatsLoaded: true,
        error: null,
        projectStats: action.payload,
      };
    }
    case types.FETCH_A_PROJECT_STATS_FAILED: {
      return {
        ...state,
        projectStatsLoading: false,
        projectStatsLoaded: true,
        error: action.payload,
      };
    }
    case types.FETCH_A_PROJECT_STATS_CURRENT_MONTH: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case types.FETCH_A_PROJECT_STATS_CURRENT_MONTH_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: null,
        currentMonthProjectStats: action.payload,
      };
    }
    case types.FETCH_A_PROJECT_STATS_CURRENT_MONTH_FAILED: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: action.payload,
      };
    }
    case types.FETCH_A_PROJECT_STATS_BILLING_USAGE: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case types.FETCH_A_PROJECT_STATS_BILLING_USAGE_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: null,
        billingPeriodUsage: action.payload,
      };
    }
    case types.FETCH_A_PROJECT_STATS_BILLING_USAGE_FAILED: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: action.payload,
      };
    }
    default:
      return state;
  }
}
