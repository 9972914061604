import { Box, Divider, Skeleton } from "@mui/material";
import React from "react";

function SkeletonConfigDetails() {
  return (
    <Box className="scrollbarContainer">
      <Box sx={{ mr: "50px", mt: "14px" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: "14px",
            ml: "21px",
          }}
        >
          <Skeleton variant="text" width="250px" height="32px" />
          <Skeleton variant="circular" width="24px" height="24px" />
        </Box>

        <Box
          sx={{
            ml: "21px",
          }}
        >
          <Divider variant="inset" sx={{ ml: "0px" }} />
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: "14px",
            }}
          >
            <Skeleton variant="text" width="250px" height="32px" />
            <Skeleton variant="text" width="150px" height="32px" />
          </Box>
          {Array.from(new Array(5)).map((e, i) => (
            <Box key={i}>
              <Skeleton
                variant="text"
                width="200px"
                height="32px"
                sx={{ m: "18px 0px 1px 0px" }}
              />

              <Skeleton variant="rectangular" width="100%" height="143px" />
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
}

export default SkeletonConfigDetails;
