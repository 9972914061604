import { all, call, put, takeLatest } from "redux-saga/effects";
import {
  FetchProjectList,
  CreateProject,
  FetchAProject,
  UpdateProject,
  FetchAProjectStats,
} from "./services";
import * as projectActions from "./actions";

export function* fetchProjectListSaga(action) {
  try {
    const response = yield call(FetchProjectList, action.payload);
    const project = response.data !== null ? response.data : [];
    yield put(projectActions.FetchProjectListSuccess(project));
  } catch (error) {
    yield put(projectActions.FetchProjectListFailed(error));
  }
}
export function* createProjectSaga(action) {
  try {
    const response = yield call(CreateProject, action.payload);
    if (response && response.data && response.data.success) {
      yield put(projectActions.CreateProjectSuccess(response.data));
    } else {
      yield put(projectActions.CreateProjectFailed(response.data));
    }
  } catch (error) {
    yield put(projectActions.CreateProjectFailed(error));
  }
}
export function* fetchAProjectSaga(action) {
  try {
    const response = yield call(FetchAProject, action.payload);
    if (response && response.data && response.data.success) {
      yield put(projectActions.FetchAProjectSuccess(response.data));
    } else {
      yield put(projectActions.FetchAProjectFailed(response.data));
    }
  } catch (error) {
    yield put(projectActions.FetchAProjectFailed(error));
  }
}
export function* updateProjectSaga(action) {
  try {
    const response = yield call(UpdateProject, action.payload);
    if (response && response.data && response.data.success) {
      yield put(projectActions.UpdateProjectSuccess(response.data));
    } else {
      yield put(projectActions.UpdateProjectFailed(response.data));
    }
  } catch (error) {
    yield put(projectActions.UpdateProjectFailed(error));
  }
}
export function* fetchAProjectStatsSaga(action) {
  try {
    const response = yield call(FetchAProjectStats, action.payload);
    if (response && response.data && response.data.success) {
      yield put(projectActions.FetchAProjectStatsSuccess(response.data));
    } else {
      yield put(projectActions.FetchAProjectStatsFailed(response.data));
    }
  } catch (error) {
    yield put(projectActions.FetchAProjectStatsFailed(error));
  }
}
export function* fetchAProjectStatsCurrentMonthSaga(action) {
  try {
    const response = yield call(FetchAProjectStats, action.payload);
    if (response && response.data && response.data.success) {
      yield put(
        projectActions.FetchAProjectStatsCurrentMonthSuccess(response.data),
      );
    } else {
      yield put(
        projectActions.FetchAProjectStatsCurrentMonthFailed(response.data),
      );
    }
  } catch (error) {
    yield put(projectActions.FetchAProjectStatsCurrentMonthFailed(error));
  }
}

export function* fetchAProjectStatsBillingUsageSaga(action) {
  try {
    const response = yield call(FetchAProjectStats, action.payload);
    if (response && response.data && response.data.success) {
      yield put(
        projectActions.FetchAProjectStatsBillingUsageSuccess(response.data),
      );
    } else {
      yield put(
        projectActions.FetchAProjectStatsBillingUsageFailed(response.data),
      );
    }
  } catch (error) {
    yield put(projectActions.FetchAProjectStatsBillingUsageFailed(error));
  }
}

export function* projectWatcher() {
  yield all([
    takeLatest(projectActions.types.PROJECT_LIST, fetchProjectListSaga),
    takeLatest(projectActions.types.CREATE_PROJECT, createProjectSaga),
    takeLatest(projectActions.types.FETCH_A_PROJECT, fetchAProjectSaga),
    takeLatest(projectActions.types.UPDATE_PROJECT, updateProjectSaga),
    takeLatest(
      projectActions.types.FETCH_A_PROJECT_STATS,
      fetchAProjectStatsSaga,
    ),
    takeLatest(
      projectActions.types.FETCH_A_PROJECT_STATS_CURRENT_MONTH,
      fetchAProjectStatsCurrentMonthSaga,
    ),
    takeLatest(
      projectActions.types.FETCH_A_PROJECT_STATS_BILLING_USAGE,
      fetchAProjectStatsBillingUsageSaga,
    ),
  ]);
}
