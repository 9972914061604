import { Box, Skeleton } from "@mui/material";
import React from "react";

const styles = {
  subheader_box: {
    width: "100%",
    pt: "70px",
    pl: "24px",
    pr: "32px",
    borderBottom: "1px solid rgba(0,0,0,0.11)",
  },
};

function SkeletonSearchSection({ marginTop }) {
  /* istanbul ignore next */
  return (
    <Box>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          p: "9px 30px 10px 25px",
          borderBottom: "1px solid rgba(0,0,0,0.1)",
        }}
      >
        <Box
          sx={{
            justifyContent: "flex-start",
            display: "flex",
            mt: { marginTop },
          }}
        >
          <Skeleton
            variant="rectangular"
            width="300px"
            height="40px"
            sx={{ borderRadius: "25px" }}
          />
          <Box
            sx={{
              p: "5px 7px 0px 14px",
              display: "flex",
              flexWrap: "wrap",
            }}
          >
            <Skeleton
              variant="rectangular"
              width="109px"
              height="30px"
              sx={{ borderRadius: "25px" }}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default SkeletonSearchSection;
