import { Box, Skeleton } from "@mui/material";
import React from "react";

const style = {
  root: {
    pb: "42px",
    backgroundColor: "#fafafa",
    "&::-webkit-scrollbar-thumb": {
      borderRadius: "100px",
      border: "3px solid transparent",
      backgroundClip: "content-box",
      height: "58px",
    },
    "&::-webkit-scrollbar": {
      width: "12px",
    },
    "&::-webkit-scrollbar-track-piece": {
      borderLeft: "1px solid rgba(0,0,0,0.12)",
    },
  },
  containerBox: {
    mr: "40px",
    ml: "40px",
    mt: "30px",
    display: "grid",
    gridGap: "20px",
    gridTemplateColumns: "repeat(auto-fill, minmax(225px, 1fr))",
  },
  card: {
    width: "100%",
    height: "185px",
    position: "relative",
    backgroundColor: "#ffffff",
    borderRadius: "4px",
    boxShadow:
      "rgb(0 0 0 / 20%) 0px 2px 1px -1px, rgb(0 0 0 / 14%) 0px 1px 1px 0px, rgb(0 0 0 / 12%) 0px 1px 3px 0px",
  },
  mainBoxAvatar: {
    cursor: "pointer",
    display: "flex",
    p: "15px",
    pb: "25px",
    borderBottom: "1px solid  rgba(0,0,0,0.12)",
  },
  mainBoxTypography: {
    ml: "20px",
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start",
    flexDirection: "column",
    mt: "10px",
  },
};
function SkeletonGridList() {
  /* istanbul ignore next */
  return (
    <Box sx={style.root}>
      <Box sx={style.containerBox}>
        {Array.from(new Array(100)).map(
          /* istanbul ignore next */ (value, index) => (
            <Box key={`gridlist-${index}`} sx={style.card}>
              <Box sx={style.mainBoxAvatar}>
                <Skeleton variant="circular" width="80px" height="80px" />
                <Box sx={style.mainBoxTypography}>
                  <Skeleton variant="text" width="100px" height="20px" />
                  <Skeleton variant="text" width="100px" height="20px" />
                </Box>
              </Box>
              <Box
                sx={{
                  height: "64px",
                  backgroundColor: "#fafafa",
                }}
              >
                <Box sx={{ p: " 6px 10px 2px", height: "38px" }}>
                  <Skeleton variant="text" width="150px" height="25px" />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Skeleton
                    variant="circular"
                    width="15px"
                    height="15px"
                    sx={{ ml: "10px" }}
                  />
                  <Skeleton
                    variant="circular"
                    width="15px"
                    height="15px"
                    sx={{ ml: "10px" }}
                  />
                  <Skeleton
                    variant="circular"
                    width="15px"
                    height="15px"
                    sx={{ ml: "10px" }}
                  />
                </Box>
              </Box>
            </Box>
          ),
        )}
      </Box>
    </Box>
  );
}

export default SkeletonGridList;
