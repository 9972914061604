import { call, put, takeLatest, all, takeEvery } from "redux-saga/effects";
import * as uiActions from "./actions";
import { ValidateConnector } from "./services";

export function* ValidateConnectorSaga(action) {
  try {
    const response = yield call(ValidateConnector, action.payload);
    /* istanbul ignore else */
    if (response.data && (response.data.valid || !response.data.canValidate)) {
      yield put(uiActions.ValidateConnectorSuccess(response.data));
    } else {
      yield put(uiActions.ValidateConnectorFail(response.data));
    }
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put(uiActions.ValidateConnectorFail(error));
  }
}

export function* uiWatcher() {
  yield all([
    takeLatest(uiActions.types.VALIDATE_CONNECTOR, ValidateConnectorSaga),
  ]);
}
