import {
  Box,
  Divider,
  ListItemIcon,
  Menu,
  MenuItem,
  SvgIcon,
} from "@mui/material";
import React, { useState } from "react";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import FolderOutlinedIcon from "@mui/icons-material/FolderOutlined";
import DashboardCustomizeOutlinedIcon from "@mui/icons-material/DashboardCustomizeOutlined";
import AttachMoneyOutlinedIcon from "@mui/icons-material/AttachMoneyOutlined";
import BusinessOutlinedIcon from "@mui/icons-material/BusinessOutlined";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import PriceChangeOutlinedIcon from "@mui/icons-material/PriceChangeOutlined";
import FeaturedPlayListOutlinedIcon from "@mui/icons-material/FeaturedPlayListOutlined";
import { Link } from "react-router-dom";
import { routes } from "../../../app-old/utilities/routes";
import {
  ADMIN_ROLE,
  CUSTOMER_ADMIN,
  SUPER_ADMIN_ROLE,
} from "../../Constants/Constants";

function AdminMenu(props) {
  const {
    adminMenuClick,
    closeMenu,
    isSuperAdmin,
    location,
    defaultCompany,
    currentUser,
    roleName,
  } = props;
  const {
    admin_settings,
    platform_settings,
    data_dictionary,
    projects,
    custom_data,
    companies,
    users,
    rate_cards,
  } = routes;
  const [hideMenu, setHideMenu] = useState(false);

  /* istanbul ignore next */
  const handleChange = (tabValue) => {
    let originValue = "";
    let companyName =
      defaultCompany === null ? "All Companies" : defaultCompany.company_name;
    if (
      location.state !== undefined &&
      location.state.activeTab !== undefined
    ) {
      let activeTab = location.state.activeTab;
      originValue = activeTab === "2" ? "company-list" : "billing-usage";
    } else {
      let locationValue = location.pathname.replaceAll("/", "");
      locationValue === "admin-settings"
        ? (originValue = "user-list")
        : locationValue === "data-dictionary"
        ? (originValue = "dictionary-list")
        : locationValue === "projects"
        ? (originValue = "project-list")
        : locationValue === "platform-settings"
        ? (originValue = "ratecard-list")
        : locationValue === "custom-data"
        ? (originValue = "customdata-list")
        : (originValue = locationValue);
    }
    window.datazoom &&
      window.datazoom.generateEvent("nav", {
        origin: originValue,
        target: tabValue.replaceAll("/", ""),
        method: "header",
        userid: currentUser.email,
        company: companyName,
      });
  };

  return (
    <Box sx={{ display: "flex", alignItems: "center", textAlign: "center" }}>
      <Menu
        // anchorEl={adminMenuClick}
        open={adminMenuClick}
        onClose={closeMenu}
        onClick={closeMenu}
        id="admin-menu"
        PaperProps={{
          elevation: 0,
          sx: {
            mt: 1.5,
            top: "36px !important",
            right: "26px",
            ml: "-10px",
            left: "auto !important",
            width: "238px !important",
            maxHeight: "360px !important",
            backgroundColor: "#FFFFFF !important",
            boxShadow:
              "0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.20) !important",
            cursor: "inherit",
            "& .MuiSvgIcon-root": {
              width: 24,
              height: 24,
              ml: "4px",
              mr: "10px",
              marginTop: "0px",
            },
            "& .MuiMenuItem-root:hover": {
              backgroundColor: "#FFFFFF",
            },
            "& .MuiDivider-root": {
              marginTop: "4px !important",
              marginBottom: "4px !important",
            },
            "& .MuiListItemIcon-root": {
              fontSize: "16px",
              color: "rgba(0,0,0,0.60) !important",
              letterSpacing: "0.15px",
              lineHeight: "26px",
              fontWeight: 400,
            },

            "& .MuiListItemIcon-root:hover": {
              color: "#263238 !important",
            },
          },
        }}
        transformOrigin={{ horizontal: "center", vertical: "top" }}
        anchorOrigin={{ horizontal: "center", vertical: "top" }}
      >
        <Link
          to={users}
          onClick={/* istanbul ignore next */ () => handleChange("user-list")}
          style={{ color: "inherit" }}
        >
          <MenuItem>
            <ListItemIcon>
              <PersonOutlineOutlinedIcon />
              Users
            </ListItemIcon>
          </MenuItem>
        </Link>
        <Link
          to={projects}
          onClick={
            /* istanbul ignore next */ () => handleChange("project-list")
          }
          style={{ color: "inherit" }}
        >
          <MenuItem>
            <ListItemIcon>
              <FolderOutlinedIcon />
              Projects
            </ListItemIcon>
          </MenuItem>
        </Link>
        {hideMenu && isSuperAdmin && roleName === SUPER_ADMIN_ROLE && (
          <Link
            onClick={
              /* istanbul ignore next */ () => handleChange("customdata-list")
            }
            to={custom_data}
            style={{ color: "inherit" }}
          >
            <MenuItem>
              <ListItemIcon>
                <DashboardCustomizeOutlinedIcon />
                Custom Data
              </ListItemIcon>
            </MenuItem>
          </Link>
        )}
        {roleName !== CUSTOMER_ADMIN && roleName !== ADMIN_ROLE && (
          <Link
            onClick={
              /* istanbul ignore next */ () => handleChange("billing-usage")
            }
            to={{ pathname: admin_settings, state: { activeTab: "3" } }}
            style={{ color: "inherit" }}
          >
            <MenuItem>
              <ListItemIcon>
                <AttachMoneyOutlinedIcon />
                Billing & Usage
              </ListItemIcon>
            </MenuItem>
          </Link>
        )}
        {isSuperAdmin && roleName === SUPER_ADMIN_ROLE && (
          <>
            <Divider />
            <Box>
              <Link
                onClick={
                  /* istanbul ignore next */ () => handleChange("company-list")
                }
                to={companies}
                style={{ color: "inherit" }}
              >
                <MenuItem>
                  <ListItemIcon>
                    <BusinessOutlinedIcon />
                    Companies
                  </ListItemIcon>
                </MenuItem>
              </Link>
              <Link
                onClick={
                  /* istanbul ignore next */ () => handleChange("ratecard-list")
                }
                to={
                  process.env.REACT_APP_NEW_RATE_CARD === "true"
                    ? rate_cards
                    : platform_settings
                }
                style={{ color: "inherit" }}
              >
                <MenuItem>
                  <ListItemIcon>
                    <PriceChangeOutlinedIcon />
                    Rate Cards
                  </ListItemIcon>
                </MenuItem>
              </Link>

              <Link
                onClick={
                  /* istanbul ignore next */ () =>
                    handleChange("dictionary-list")
                }
                to={data_dictionary}
                style={{ color: "inherit" }}
              >
                <MenuItem>
                  <ListItemIcon>
                    <FeaturedPlayListOutlinedIcon />
                    Data Dictionaries
                  </ListItemIcon>
                </MenuItem>
              </Link>
              {process.env.REACT_APP_COLLECTOR_MANAGEMENT === "true" && (
                <MenuItem>
                  <ListItemIcon>
                    <SvgIcon>
                      <path d="M19.3377 13.532C19.1277 13.308 17.7267 11.809 17.3267 11.418C17.0464 11.1493 16.673 10.9995 16.2847 11H14.5277L17.5917 13.994H14.0477C13.9996 13.9931 13.952 14.0049 13.9099 14.0283C13.8678 14.0516 13.8325 14.0857 13.8077 14.127L14.0477 13.994H5.95174L6.19174 14.127C6.1668 14.0858 5.99384 14.0173 5.95174 13.994C5.99987 13.9933 5.90964 13.9707 5.95174 13.994H2.40774L5.47074 11H3.71474C3.31774 11 2.93874 11.159 2.67274 11.418C2.27274 11.81 0.871737 13.309 0.661737 13.532C0.172737 14.053 -0.0962632 14.468 0.0317368 14.981L0.592737 18.055C0.720737 18.569 1.28374 18.991 1.84474 18.991H18.1567C18.7177 18.991 19.2807 18.569 19.4087 18.055L19.9697 14.981C20.0957 14.468 19.8277 14.053 19.3377 13.532Z M15.0312 4.03125L14.1532 4.63539C13.4519 5.11836 12.9105 5.56631 12.5482 6.18398C12.2129 6.75568 12.0382 7.47305 12.0057 8.42416H14.8125L9.89062 12.9688L4.96875 8.42416H7.7909C7.87293 6.85838 8.53137 5.69178 9.75281 4.95314C10.7656 4.34148 12.1654 4.03125 13.9173 4.03125H15.0312Z" />
                    </SvgIcon>
                    Collector Management
                  </ListItemIcon>
                </MenuItem>
              )}
            </Box>
          </>
        )}
      </Menu>
    </Box>
  );
}
export default AdminMenu;
