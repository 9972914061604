import { call, put, takeLatest, all } from "redux-saga/effects";
import {
  FetchApiKeys,
  EnableApiKeys,
  DisableApiKeys,
  CreateApiKey,
} from "./service";
import * as apiKeyAction from "./actions";

export function* fetchApiKeys(action) {
  try {
    const response = yield call(FetchApiKeys, action.payload);
    const apiKeys =
      response && response.data && response.data.data ? response.data.data : [];
    yield put(apiKeyAction.FetchAPIKeySuccess(apiKeys));
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put(apiKeyAction.FetchAPIKeysFailed(error));
  }
}

export function* enableApiKeys(action) {
  try {
    const response = yield call(EnableApiKeys, action.payload);
    const apiKeys =
      response && response.data && response.data.data ? response.data.data : [];
    yield put(apiKeyAction.APIKeysEnableSuccess(apiKeys));
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put(apiKeyAction.APIKeysEnableFailed(error));
  }
}

export function* disableApiKeys(action) {
  try {
    const response = yield call(DisableApiKeys, action.payload);
    const apiKeys =
      response && response.data && response.data.data ? response.data.data : [];
    yield put(apiKeyAction.APIKeysDisableSuccess(apiKeys));
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put(apiKeyAction.APIKeysDisableFailed(error));
  }
}

export function* createApiKey(action) {
  try {
    const response = yield call(CreateApiKey, action.payload);
    const apiKeys =
      response && response.data && response.data.data ? response.data.data : [];
    yield put(apiKeyAction.CreateApiKeySuccess(apiKeys));
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put(apiKeyAction.CreateApiKeyFailed(error));
  }
}
export function* apiKeyWatcher() {
  yield all([
    takeLatest(apiKeyAction.types.FETCH_API_KEYS, fetchApiKeys),
    takeLatest(apiKeyAction.types.CHANGE_STATUS, enableApiKeys),
    takeLatest(apiKeyAction.types.DISABLE_STATUS, disableApiKeys),
    takeLatest(apiKeyAction.types.CREATE_API_KEY, createApiKey),
  ]);
}
