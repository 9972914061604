export const getCustomDataObj = (data) => ({
  dataPointId: data.id,
  type: data.type,
  name: data.messageName,
  collectorType: data.collectorType,
  fieldType: data.fieldType,
  definition: data.definition,
  piiHandling: data.piiHandling,
  customerCode: data.customerCode,
  collectorConfigId: data.collectorConfigId,
  status: data.status,
});

export const getCustomParam = /* istanbul ignore next */ (data) => ({
  companyId: data.companyId,
  collectorConfig: data.collectorConfig === "" ? null : data.collectorConfig,
  collectorType: data.collectorType === "" ? null : data.collectorType,
  customItemType: data.customItemType === "" ? null : data.customItemType,
  states: data.states === "" ? null : data.states,
  status: data.status === "" ? null : data.status,
});
