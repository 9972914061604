/* eslint-disable camelcase */
import axios from "axios";
import { baseApiV2 } from "../../constants/Presets";
import { ORDER_DESC } from "../../constants/Constants";

export function FetchApiKeys(payload) {
  const url = `${baseApiV2}api-key/list?`;
  const params = [];
  if (payload.id) {
    params.push(`customer_code=${payload.id}`);
  }
  const order = payload.order || ORDER_DESC;
  params.push(`order=${order}`);
  const order_by = payload.orderBy || "createdOn";
  params.push(`order_by=${order_by}`);
  const page = payload.page || 0;
  params.push(`page=${page}`);
  const size = payload.size || 1000;
  params.push(`size=${size}`);
  const paramStr = params.join("&");
  return axios.get(url + paramStr);
}

export function EnableApiKeys(payload) {
  if (payload.key) {
    return axios({
      method: "patch",
      url: `${baseApiV2}api-key/disable?api_key=${payload.key.value}&updated_by=${payload.updatedBy.value}`,
    });
  }
}

export function DisableApiKeys(payload) {
  if (payload.key) {
    return axios({
      method: "patch",
      url: `${baseApiV2}api-key/enable?api_key=${payload.key.value}&updated_by=${payload.updatedBy.value}`,
    });
  }
}

export function CreateApiKey(payload) {
  if (payload) {
    return axios({
      method: "post",
      url: `${baseApiV2}api-key/create?created_by=${payload.createdBy}&customer_code=${payload.companyUUID}`,
    });
  }
}
