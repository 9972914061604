import { types } from "./actions";

const initialState = {
  list: [],
  error: null,
  loading: false,
  loaded: false,
  isLoading: false,
  isLoaded: false,
  currentDataPoint: [],
  dataDictionaryVersion: [],
  successResponse: null,
  errorResponse: null,
  authLoading: false,
  authLoaded: false,
  listLoading: false,
  listLoaded: false,
  logs: [],
  datapointmanagementlist: [],
  authoredlist: [],
  successResponseAuthored: null,
  erroreResponseAuthored: null,
  createJiraSuccessResponse: null,
  createJiraErrorResponse: null,
};
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case types.DATA_DICTIONARY_LIST: {
      return {
        ...state,
        isLoading: true,
        isLoaded: false,
      };
    }
    case types.DATA_DICTIONARY_LIST_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        ...action.payload,
      };
    }
    case types.DATA_DICTIONARY_LIST_FAILED: {
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        error: action.payload,
      };
    }
    case types.FETCH_A_DATA_DICTIONARY: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case types.FETCH_A_DATA_DICTIONARY_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        currentDataPoint: action.payload,
      };
    }
    case types.FETCH_A_DATA_DICTIONARY_FAILED: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: action.payload,
      };
    }
    case types.FETCH_DATA_DICTIONARY_VERSION: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case types.FETCH_DATA_DICTIONARY_VERSION_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        dataDictionaryVersion: action.payload,
      };
    }
    case types.FETCH_DATA_DICTIONARY_VERSION_FAILED: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: action.payload,
      };
    }
    case types.CREATE_DATA_DICTIONARY: {
      return {
        ...state,
        loading: true,
        loaded: false,
        errorResponse: null,
        successResponse: null,
      };
    }
    case types.CREATE_DATA_DICTIONARY_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        successResponse: action.payload,
      };
    }
    case types.CREATE_DATA_DICTIONARY_FAILED: {
      return {
        ...state,
        loading: false,
        loaded: true,
        errorResponse: action.payload,
      };
    }
    case types.CLEAR_A_DATA_DICTIONARY: {
      return {
        ...state,
        currentDataPoint: [],
        successResponse: null,
        logs: [],
      };
    }
    case types.CLEAR_AUTHOREDLIST: {
      return {
        ...state,
        authoredlist: [],
        authLoading: false,
        authLoaded: false,
      };
    }
    case types.CLEAR_DATA_DICTIONARY: {
      return {
        ...initialState,
      };
    }

    case types.FETCH_A_DATA_POINT_LOGS: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case types.FETCH_A_DATA_POINT_LOGS_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        logs: action.payload,
      };
    }
    case types.FETCH_A_DATA_POINT_LOGS_FAILED: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: action.payload,
      };
    }
    case types.FETCH_DATA_POINT_MANAGEMENT_LIST: {
      return {
        ...state,
        listLoading: true,
        listLoaded: false,
      };
    }
    case types.FETCH_DATA_POINT_MANAGEMENT_LIST_SUCCESS: {
      return {
        ...state,
        listLoading: false,
        listLoaded: true,
        datapointmanagementlist: action.payload,
      };
    }
    case types.FETCH_DATA_POINT_MANAGEMENT_LIST_FAILED: {
      return {
        ...state,
        listLoading: false,
        listLoaded: true,
        error: action.payload,
      };
    }
    case types.FETCH_AUTHORED_LIST: {
      return {
        ...state,
        authLoading: true,
        authLoaded: false,
      };
    }
    case types.FETCH_AUTHORED_LIST_SUCCESS: {
      return {
        ...state,
        authLoading: false,
        authLoaded: true,
        authoredlist: action.payload,
      };
    }
    case types.FETCH_AUTHORED_LIST_FAILED: {
      return {
        ...state,
        authLoading: false,
        authLoaded: true,
        error: action.payload,
      };
    }
    case types.UPDATE_DATAPOINT_MANAGEMENT: {
      return {
        ...state,
        listLoading: true,
        listLoaded: false,
        successResponseAuthored: null,
      };
    }
    case types.UPDATE_DATAPOINT_MANAGEMENT_SUCCESS: {
      return {
        ...state,
        error: null,
        listLoading: false,
        listLoaded: true,
        successResponseAuthored: action.payload,
      };
    }
    case types.UPDATE_DATAPOINT_MANAGEMENT_FAILED: {
      return {
        ...state,
        listLoading: false,
        listLoaded: true,
        erroreResponseAuthored: action.payload,
      };
    }
    case types.CREATE_DATAPOINT_JIRA_TICKET: {
      return {
        ...state,
        loading: true,
        loaded: false,
        createJiraSuccessResponse: null,
        createJiraErrorResponse: null,
      };
    }
    case types.CREATE_DATAPOINT_JIRA_TICKET_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        createJiraSuccessResponse: action.payload,
      };
    }
    case types.CREATE_DATAPOINT_JIRA_TICKET_FAILED: {
      return {
        ...state,
        loading: false,
        loaded: true,
        createJiraErrorResponse: action.payload,
      };
    }
    case types.CLEAR_AUTHORED_RESPONSE: {
      return {
        ...state,
        successResponseAuthored: null,
        erroreResponseAuthored: null,
      };
    }
    case types.CLEAR_CREATE_JIRA_RESPONSE: {
      return {
        ...state,
        createJiraSuccessResponse: null,
        createJiraErrorResponse: null,
      };
    }
    default:
      return state;
  }
}
