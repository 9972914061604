// Action Creator
export const types = {
  DATA_DICTIONARY_LIST: "[DataDictionary] Data Dictionary List",
  DATA_DICTIONARY_LIST_SUCCESS: "[DataDictionary] Data Dictionary Success",
  DATA_DICTIONARY_LIST_FAILED: "[DataDictionary] Data Dictionary Failed",
  FETCH_A_DATA_DICTIONARY: "[DataDictionary] Fetch a Data Dictionary",
  FETCH_A_DATA_DICTIONARY_SUCCESS:
    "[DataDictionary] Fetch a Data Dictionary Success",
  FETCH_A_DATA_DICTIONARY_FAILED:
    "[DataDictionary] Fetch a Data Dictionary Failed",
  FETCH_DATA_DICTIONARY_VERSION:
    "[DataDictionary] Fetch Data Dictionary Version",
  FETCH_DATA_DICTIONARY_VERSION_SUCCESS:
    "[DataDictionary] Fetch Data Dictionary Version Success",
  FETCH_DATA_DICTIONARY_VERSION_FAILED:
    "[DataDictionary] Fetch Data Dictionary Version Failed",
  CREATE_DATA_DICTIONARY: "[DataDictionary] Create Data Dictionary",
  CREATE_DATA_DICTIONARY_SUCCESS:
    "[DataDictionary] Create Data Dictionary success",
  CREATE_DATA_DICTIONARY_FAILED:
    "[DataDictionary] Create Data Dictionary failed",
  CLEAR_A_DATA_DICTIONARY: "[DataDictionary] Clear a Data Dictionary",
  CLEAR_AUTHOREDLIST: "[DataDictionary] Clear Authored List",
  CLEAR_DATA_DICTIONARY: "[DataDictionary] Clear Data Dictionary",
  FETCH_A_DATA_POINT_LOGS: "[DataDictionary] Fetch a Data Point Logs",
  FETCH_A_DATA_POINT_LOGS_SUCCESS:
    "[DataDictionary] Fetch a Data Point Logs Success",
  FETCH_A_DATA_POINT_LOGS_FAILED:
    "[DataDictionary] Fetch a Data Point Logs Failed",
  FETCH_DATA_POINT_MANAGEMENT_LIST:
    "[DataDictionary] Fetch  Data Point Management List",
  FETCH_DATA_POINT_MANAGEMENT_LIST_SUCCESS:
    "[DataDictionary] Fetch  Data Point Management List Success",
  FETCH_DATA_POINT_MANAGEMENT_LIST_FAILED:
    "[DataDictionary] Fetch  Data Point Management List Failed",
  FETCH_AUTHORED_LIST: "[DataDictionary] Fetch Authored List",
  FETCH_AUTHORED_LIST_SUCCESS: "[DataDictionary] Fetch Authored List Success",
  FETCH_AUTHORED_LIST_FAILED: "[DataDictionary] Fetch Authored List Failed",
  CREATE_DATAPOINT_JIRA_TICKET:
    "[DataDictionary] Create Data Point Jira Ticket",
  CREATE_DATAPOINT_JIRA_TICKET_SUCCESS:
    "[DataDictionary] Create Data Point Jira Ticket Success",
  CREATE_DATAPOINT_JIRA_TICKET_FAILED:
    "[DataDictionary] Create Data Point Jira Ticket Failed",
  UPDATE_DATAPOINT_MANAGEMENT: "[DataDictionary] Update Data Point Management",
  UPDATE_DATAPOINT_MANAGEMENT_SUCCESS:
    "[DataDictionary] Update Data Point Management Success",
  UPDATE_DATAPOINT_MANAGEMENT_FAILED:
    "[DataDictionary] Update Data Point Management Failed",
  CLEAR_AUTHORED_RESPONSE: "[DataDictionary] Clear Authored Response",
  CLEAR_CREATE_JIRA_RESPONSE: "[DataDictionary] Clear Clear Jira Response",
};

export const FetchDataDictionaryList = (
  dataPointId,
  group,
  order,
  order_By,
  query,
  page,
  size,
  filters,
) => ({
  type: types.DATA_DICTIONARY_LIST,
  payload: {
    dataPointId,
    group,
    order,
    order_By,
    query,
    page,
    size,
    filters,
  },
});

export const FetchDataDictionaryListSuccess = (list) => ({
  type: types.DATA_DICTIONARY_LIST_SUCCESS,
  payload: list,
});
export const FetchDataDictionaryListFailed = (error) => ({
  type: types.DATA_DICTIONARY_LIST_FAILED,
  payload: error,
});

export const FetchADataPoint = (dataPointId) => ({
  type: types.FETCH_A_DATA_DICTIONARY,
  payload: { dataPointId },
});
export const FetchADataPointSuccess = (list) => ({
  type: types.FETCH_A_DATA_DICTIONARY_SUCCESS,
  payload: list,
});
export const FetchADataPointFailed = (error) => ({
  type: types.FETCH_A_DATA_DICTIONARY_FAILED,
  payload: error,
});
export const FetchDataDictionaryVersion = () => ({
  type: types.FETCH_DATA_DICTIONARY_VERSION,
});
export const FetchDataDictionaryVersionSuccess = (response) => ({
  type: types.FETCH_DATA_DICTIONARY_VERSION_SUCCESS,
  payload: response,
});
export const FetchDataDictionaryVersionFailed = (error) => ({
  type: types.FETCH_DATA_DICTIONARY_VERSION_FAILED,
  payload: error,
});
export const CreateDataDictionary = (dataDictionary) => ({
  type: types.CREATE_DATA_DICTIONARY,
  payload: dataDictionary,
});
export const CreateDataDictionarySuccess = (response) => ({
  type: types.CREATE_DATA_DICTIONARY_SUCCESS,
  payload: response,
});
export const CreateDataDictionaryFailed = (error) => ({
  type: types.CREATE_DATA_DICTIONARY_FAILED,
  payload: error,
});
export const ClearADataDictionary = () => ({
  type: types.CLEAR_A_DATA_DICTIONARY,
});
export const ClearAuthoredList = () => ({
  type: types.CLEAR_AUTHOREDLIST,
});
export const ClearDataDictionary = () => ({
  type: types.CLEAR_DATA_DICTIONARY,
});
export const FetchADataPointLogs = (dataPointId) => ({
  type: types.FETCH_A_DATA_POINT_LOGS,
  payload: dataPointId,
});
export const FetchADataPointLogsSuccess = (logs) => ({
  type: types.FETCH_A_DATA_POINT_LOGS_SUCCESS,
  payload: logs,
});
export const FetchADataPointLogsFailed = (error) => ({
  type: types.FETCH_A_DATA_POINT_LOGS_FAILED,
  payload: error,
});
export const FetchDatapointManagementListFailed = (error) => ({
  type: types.FETCH_DATA_POINT_MANAGEMENT_LIST_FAILED,
  payload: error,
});

export const FetchAuthoredListFailed = (error) => ({
  type: types.FETCH_AUTHORED_LIST_FAILED,
  payload: error,
});
export const CreateDataPointJiraticket = (dataPointId) => ({
  type: types.CREATE_DATAPOINT_JIRA_TICKET,
  payload: dataPointId,
});
export const CreateDataPointJiraTicketSuccess = (Id) => ({
  type: types.CREATE_DATAPOINT_JIRA_TICKET_SUCCESS,
  payload: Id,
});
export const CreateDataPointJiraTicketFailed = (error) => ({
  type: types.CREATE_DATAPOINT_JIRA_TICKET_FAILED,
  payload: error,
});
export const UpdateDataPointManagement = (data) => ({
  type: types.UPDATE_DATAPOINT_MANAGEMENT,
  payload: data,
});
export const UpdatDataPointManagementSuccess = (response) => ({
  type: types.UPDATE_DATAPOINT_MANAGEMENT_SUCCESS,
  payload: response,
});
export const UpdatDataPointManagementFailed = (error) => ({
  type: types.UPDATE_DATAPOINT_MANAGEMENT_FAILED,
  payload: error,
});

export const FetchDatapointManagementList = (
  dataPointId,
  order,
  order_By,
  query,
  size,
  filter,
) => ({
  type: types.FETCH_DATA_POINT_MANAGEMENT_LIST,
  payload: { dataPointId, order, order_By, query, size, filter },
});
export const FetchAuthoredList = (
  dataPointId,
  order,
  order_By,
  query,
  size,
  filter,
) => ({
  type: types.FETCH_AUTHORED_LIST,
  payload: { dataPointId, order, order_By, query, size, filter },
});

export const FetchAuthoredListSuccess = (authoredlist) => ({
  type: types.FETCH_AUTHORED_LIST_SUCCESS,
  payload: authoredlist,
});
export const FetchDatapointManagementListSuccess = (
  datapointmanagementlist,
) => ({
  type: types.FETCH_DATA_POINT_MANAGEMENT_LIST_SUCCESS,
  payload: datapointmanagementlist,
});
export const ClearAuthoredResponse = () => ({
  type: types.CLEAR_AUTHORED_RESPONSE,
});
export const ClearCreateJiraResponse = () => ({
  type: types.CLEAR_CREATE_JIRA_RESPONSE,
});
