import { Box, Skeleton } from "@mui/material";
import React from "react";

const styles = {
  container: {
    backgroundColor: "white",
    position: "fixed",
    width: "100%",
    zIndex: 1000,
  },
  containerBox: {
    width: "100%",
    display: "inline-block",
    p: "85px 0px 41px 0px",
    borderBottom: "1px solid rgba(0,0,0,0.1)",
  },
};
function SkeletonStepperSection({ count }) {
  /* istanbul ignore next */
  return (
    <Box sx={styles.container}>
      <Box sx={styles.containerBox}>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            p: "0 8px ",
          }}
        >
          {Array.from(new Array(count)).map(
            /* istanbul ignore next */ (value, index) => (
              <Box
                key={`stepper-${index}`}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  width: "100%",
                  pl: "8px",
                  pr: "8px",
                }}
              >
                <Skeleton variant="circular" width="24px" height="24px" />
                <Skeleton
                  variant="rectangular"
                  height="24px"
                  width="200px"
                  sx={{ mt: "12px" }}
                />
              </Box>
            ),
          )}
        </Box>
      </Box>
    </Box>
  );
}

export default SkeletonStepperSection;
