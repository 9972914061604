import React from "react";
import CopyrightIcon from "@mui/icons-material/Copyright";
import { Typography, Box, Link } from "@mui/material";

export default function Footer() {
  return (
    <footer
      style={{
        position: "fixed",
        bottom: 0,
        left: 0,
        width: "100%",
        zIndex: "1400",
      }}
    >
      <Box
        sx={{
          bgcolor: "#263238",
          height: "30px",
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          color: "rgba(255,255,255,0.7)",
          pl: "20px",
          pr: "20px",
          fontSize: "12px",
          letterSpacing: "0.12857144px",
          lineHeight: "20px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "216px",
          }}
        >
          <Typography
            variant="p"
            noWrap
            component="span"
            sx={{ ml: "2px", fontWeight: 400 }}
          >
            &copy; {new Date().getFullYear()} Datazoom. All Rights Reserved.
          </Typography>
        </Box>
        <Box
          sx={{
            textAlign: "right",
          }}
        >
          <Link
            style={{ marginRight: "13px", fontWeight: 400 }}
            underline="none"
            target="_blank"
            rel="noopener"
            href="https://www.datazoom.io/legal-documents-and-notices/"
            color="inherit"
          >
            Privacy
          </Link>
          <Link
            style={{ fontWeight: 400, marginRight: "4px" }}
            underline="none"
            target="_blank"
            rel="noopener"
            href="https://www.datazoom.io/terms-and-conditions/"
            color="inherit"
          >
            Terms
          </Link>
        </Box>
      </Box>
    </footer>
  );
}
