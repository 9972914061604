import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router";
import { styled } from "@mui/material/styles";
import { AppBar, Tabs, Tab, Box, IconButton, Typography } from "@mui/material";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import AccountCircleSharpIcon from "@mui/icons-material/AccountCircleSharp";
import { Routes } from "../../Utilities";
import { DATAZOOM_LOGO, USER_ROLE, SUPER_ADMIN_ROLE } from "../../Constants";
import { Link } from "react-router-dom";
import UserMenu from "../UserMenuSettings/UserMenu";
import AdminMenu from "../AdminMenuSettings/AdminMenu";
import ChangeCompany from "../ChangeCompanyMenu/ChangeCompany";
import useMediaQuery from "@mui/material/useMediaQuery";
import {
  ADMIN_ROLE,
  BILLING_ADMIN,
  CUSTOMER_ADMIN,
  DZ_LOGO,
} from "../../Constants/Constants";
import { useCacheBuster } from "react-cache-buster";

/* istanbul ignore next */
const StyledTabs = styled((props) => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))({
  "& .MuiTabs-indicator": {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
    bottom: "10px",
  },
  "& .MuiTabs-indicatorSpan": {
    maxWidth: "42.67px",
    width: "42.67px",
    height: "2.5px",
    backgroundColor: "#007AC9",
  },
});
/* istanbul ignore next */
const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: "none",
    fontWeight: 500,
    fontSize: theme.typography.pxToRem(14),
    letterSpacing: "0.3px",
    textAlign: "center",
    lineHeight: "24px",
    color: "rgba(255,255,255,0.7)",
    width: useMediaQuery("(min-width:850px)") ? "128px" : "120px",
    "&.Mui-selected": {
      color: "#FFFFFF",
    },
    "&.Mui-focusVisible": {
      backgroundColor: "rgba(100, 95, 228, 0.32)",
    },
    "&:hover": {
      background: "rgba(144,182,212,0.25)",
      color: "#FFFFFF",
    },
    padding: "20px 16px 16px",
  }),
);

function Header(props) {
  const {
    auth,
    companyList,
    defaultCompany,
    selectCompany,
    route,
    currentUser,
    starCompanyHandler,
    unStarCompanyHandler,
    favoriteCompanies,
  } = props;
  const {
    dashboard,
    datapipeList,
    collectorList,
    connectorList,
    payment,
    adminSettings,
    platformSettings,
    data_dictionary,
    projects,
    custom_data,
    companies,
    users,
    rate_cards,
  } = Routes;
  const [tab, setTab] = useState(dashboard);
  const [menuVisible, setMenuVisible] = useState(false);
  const [adminMenu, setAdminMenu] = useState(false);
  const [companyMenu, setCompanyMenu] = useState(false);
  const [iconColor, setIconColor] = useState(false);
  const [isMount, setIsMount] = useState();
  const mediaQueryLogo = useMediaQuery("(min-width:900px)");
  const mediaQueryComDisable = useMediaQuery("(min-width:800px)");
  const mediaQueryPadding = useMediaQuery("(min-width:622px)");
  const history = useHistory();
  const location = useLocation();
  const { checkCacheStatus } = useCacheBuster();

  const isSuperAdmin =
    companyList?.length > 0 || auth?.user?.roleName === SUPER_ADMIN_ROLE;

  useEffect(() => {
    if (isMount !== undefined) checkCacheStatus();
  }, [location]);

  useEffect(() => {
    setIsMount(true);
    /* istanbul ignore if */
  }, []);

  useEffect(() => {
    location.pathname.includes("dashboard")
      ? setTab(dashboard)
      : location.pathname.includes("pipe")
      ? setTab(datapipeList)
      : location.pathname.includes("collector")
      ? setTab(collectorList)
      : location.pathname.includes("connector")
      ? setTab(connectorList)
      : setTab(false);
  });
  const isLocation = () => {
    const locations = [
      data_dictionary,
      adminSettings,
      platformSettings,
      projects,
      custom_data,
      companies,
      users,
      rate_cards,
    ];
    return locations.some((loc) => location.pathname.includes(loc));
  };
  useEffect(() => {
    /* istanbul ignore if */
    if (!iconColor) {
      if (isLocation()) {
        setIconColor(true);
      }
    }
    /* istanbul ignore if */
    if (iconColor && !adminMenu && !isLocation()) {
      setIconColor(false);
    }
  });
  /* istanbul ignore next */
  const handleChange = (tabValue, targetVal) => {
    let originValue = "";
    let companyName =
      defaultCompany === null ? "All Companies" : defaultCompany.company_name;
    if (
      location.state !== undefined &&
      location.state.activeTab !== undefined
    ) {
      let activeTab = location.state.activeTab;
      originValue = activeTab === "2" ? "company-list" : "billing-usage";
    } else {
      let locationValue = location.pathname.replaceAll("/", "");
      locationValue === "admin-settings"
        ? (originValue = "user-list")
        : locationValue === "data-dictionary"
        ? (originValue = "dictionary-list")
        : locationValue === "projects"
        ? (originValue = "project-list")
        : locationValue === "platform-settings"
        ? (originValue = "ratecard-list")
        : (originValue = locationValue);
    }
    // history.push(tabValue);
    setTab(tabValue);
    window.datazoom &&
      window.datazoom.generateEvent("nav", {
        origin: originValue,
        target: targetVal != null ? targetVal : tabValue.replaceAll("/", ""),
        method: "header",
        userid: currentUser.email,
        company: companyName,
      });
  };
  /* istanbul ignore next */
  const handleClick = (menu) => {
    /* istanbul ignore if */
    if (menu === "usermenu") {
      setMenuVisible((menuVisible) => !menuVisible);
    }
    /* istanbul ignore if */
    if (menu === "adminmenu") {
      setAdminMenu((adminMenu) => !adminMenu);
      setIconColor((iconColor) => !iconColor);
    }
    /* istanbul ignore if */
    if (menu === "companymenu") {
      setCompanyMenu((companyMenu) => !companyMenu);
    }
  };
  /* istanbul ignore next */
  const closeMenu = () => {
    setMenuVisible(false);
    setAdminMenu(false);
    setCompanyMenu(false);
    setTimeout(() => {
      setMenuVisible(false);
      setAdminMenu(false);
      setCompanyMenu(false);
    }, 200);
  };

  return location.pathname !== payment ? (
    <AppBar
      position="fixed"
      elevation={0}
      style={{
        background: "#000A12",
        width: "100%",
        padding: mediaQueryPadding ? "0 20px" : "0 15px",
      }}
    >
      <Box
        sx={{
          width: "100%",
          height: "60px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            flexBasis: "auto",
            width: "100%",
            height: "20px",
            lineHeight: "18px",
            ...(!mediaQueryLogo && {
              minWidth: "30px",
              height: "20px",
              lineHeight: "18px",
              display: "inline-block",
              whiteSpace: "nowrap",
            }),
          }}
        >
          <Link
            to={dashboard}
            onClick={
              /* istanbul ignore next */ () => handleChange(dashboard, "home")
            }
            className="logo"
          >
            <img
              style={{
                filter: "invert(1)",
                width: mediaQueryLogo ? "126px" : "64px",
              }}
              src={mediaQueryLogo ? DATAZOOM_LOGO : DZ_LOGO}
              alt="datazoom logo"
            />
          </Link>
        </Box>
        {auth && auth.isLoggedIn && (
          <>
            <Box
              sx={{
                flexBasis: "auto",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <StyledTabs value={tab} aria-label="Header">
                <StyledTab
                  label="DASHBOARD"
                  value={dashboard}
                  component={Link}
                  to={dashboard}
                  onClick={
                    /* istanbul ignore next */ () => handleChange(dashboard)
                  }
                />
                <StyledTab
                  label="DATA PIPES"
                  value={datapipeList}
                  component={Link}
                  to={datapipeList}
                  onClick={
                    /* istanbul ignore next */ () => handleChange(datapipeList)
                  }
                />
                <StyledTab
                  label="COLLECTORS"
                  value={collectorList}
                  component={Link}
                  to={collectorList}
                  onClick={
                    /* istanbul ignore next */ () => handleChange(collectorList)
                  }
                />
                <StyledTab
                  label="CONNECTORS"
                  value={connectorList}
                  component={Link}
                  to={connectorList}
                  onClick={
                    /* istanbul ignore next */ () => handleChange(connectorList)
                  }
                />
              </StyledTabs>
            </Box>
            <Box
              sx={{
                flexBasis: "auto",
                display: "flex",
                justifyContent: "flex-end",
                width: "100%",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  color: "#FFFFFF",
                  alignItems: "center",
                  display: "flex",
                }}
              >
                {auth.user.roleName !== USER_ROLE && (
                  /* istanbul ignore next */
                  <IconButton
                    sx={{
                      "&:hover": {
                        backgroundColor: "rgba(255, 255, 255, 0.16)",
                        cursor: "pointer",
                      },
                    }}
                    color="primary"
                    aria-label="settings"
                    component="span"
                    name="adminmenu"
                    onClick={
                      /* istanbul ignore next */ () => handleClick("adminmenu")
                    }
                  >
                    <SettingsOutlinedIcon
                      style={{
                        color: "rgba(255, 255, 255, 0.5)",
                        width: "24px",
                        height: "24px",
                        ...(iconColor
                          ? { color: "#1976d2" }
                          : { color: "rgba(255, 255, 255, 0.5)" }),
                      }}
                    />
                    {adminMenu && (
                      <AdminMenu
                        adminMenuClick={adminMenu}
                        closeMenu={closeMenu}
                        isSuperAdmin={isSuperAdmin}
                        location={location}
                        defaultCompany={defaultCompany}
                        currentUser={currentUser}
                        roleName={auth?.user?.roleName}
                      />
                    )}
                  </IconButton>
                )}
                {mediaQueryComDisable && (
                  <Box
                    sx={{ m: "0 6px", display: "flex", alignItems: "center" }}
                  >
                    <Typography
                      variant="p"
                      noWrap
                      component="span"
                      sx={{
                        fontWeight: 500,
                        fontSize: "14px",
                        textAlign: "right",
                        letterSpacing: "0.1px",
                        lineHeight: "14px",
                        color: "#FFFFFF",
                        maxWidth: "162px",
                        display: "inline-block",
                        minWidth: "30px",
                        ...(isSuperAdmin &&
                          companyList?.length > 0 && {
                            cursor: "pointer",
                          }),
                      }}
                      onClick={
                        /* istanbul ignore next */ () => {
                          isSuperAdmin &&
                            companyList?.length > 0 &&
                            handleClick("companymenu");
                        }
                      }
                    >
                      {isSuperAdmin
                        ? defaultCompany
                          ? defaultCompany.company_name
                          : defaultCompany === null
                          ? "All Companies"
                          : auth.user.companyname
                        : defaultCompany
                        ? defaultCompany.company_name
                        : ""}
                    </Typography>
                    {isSuperAdmin && (
                      <KeyboardArrowDownIcon
                        style={{
                          color: "#FFFFFF",
                          opacity: 1,
                          cursor: "pointer",
                        }}
                        name="companymenu"
                        onClick={
                          /* istanbul ignore next */ () =>
                            handleClick("companymenu")
                        }
                      />
                    )}
                    {companyMenu && (
                      <ChangeCompany
                        starCompanyHandler={starCompanyHandler}
                        unStarCompanyHandler={unStarCompanyHandler}
                        companyMenu={companyMenu}
                        companyList={companyList}
                        favoriteCompanies={favoriteCompanies}
                        selectCompany={selectCompany}
                        closeMenu={closeMenu}
                        defaultCompany={defaultCompany}
                        roleName={auth?.user?.roleName}
                      />
                    )}
                  </Box>
                )}
              </Box>
              <IconButton
                sx={{
                  padding: "5px",
                  "&:hover": {
                    backgroundColor: "rgba(255, 255, 255, 0.16)",
                    cursor: "pointer",
                  },
                }}
                color="primary"
                aria-label="profile"
                component="span"
                name="usermenu"
                onClick={
                  /* istanbul ignore next */ () => handleClick("usermenu")
                }
              >
                <AccountCircleSharpIcon
                  style={{
                    color: "rgba(255, 255, 255, 0.5)",
                    width: "30px",
                    height: "30px",
                  }}
                />
                {menuVisible && (
                  <UserMenu
                    menuClick={menuVisible}
                    handleClick={handleClick}
                    auth={auth}
                    currentUser={currentUser}
                    closeMenu={closeMenu}
                  />
                )}
              </IconButton>
            </Box>
            {/* </Box> */}
          </>
        )}
      </Box>
    </AppBar>
  ) : (
    <div className="header">
      <div className="container-fluid">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
          }}
        >
          <img src="/images/datazoom-logo-com-disable.svg" alt="" />
          <div>
            <h2>Quick Start</h2>
            <h4>Let&apos;s Get Zoomin&apos;</h4>
          </div>
          <button
            type="button"
            className="btn btn-cancel btn-lg"
            data-dismiss="modal"
            onClick={
              /* istanbul ignore next */ () => {
                handleChange(dashboard);
              }
            }
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
}
export default Header;
