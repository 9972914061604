export const types = {
  FETCH_API_KEYS: "[APIKey] Fetch APIKeys",
  FETCH_API_KEYS_SUCCESS: "[APIKey] Fetch APIKeys success",
  FETCH_API_KEYS_FAILED: "[APIKey] Fetch APIKeys failed",

  CHANGE_STATUS: "[APIKey] Change APIKey status",
  CHANGE_STATUS_SUCCESS: "[APIKey] Change APIKey status success",
  CHANGE_STATUS_FAILED: "[APIKey] Change APIKey status failed",

  DISABLE_STATUS: "[APIKey] Disable APIKey status",
  DISABLE_STATUS_SUCCESS: "[APIKey] Disable APIKey status success",
  DISABLE_STATUS_FAILED: "[APIKey] Disable APIKey status failed",

  CREATE_API_KEY: "[APIKey] Create APIKey status",
  CREATE_API_KEY_SUCCESS: "[APIKey] Create APIKey status success",
  CREATE_API_KEY_FAILED: "[APIKey] Create APIKey status failed",
};

//
// ─── FETCH ALL APIKeys ACTIONS ────────────────────────────────────────────────
//

export const FetchAPIKeys = (id, order, orderBy) => ({
  type: types.FETCH_API_KEYS,
  payload: { id, order, orderBy },
});
export const FetchAPIKeySuccess = (list) => ({
  type: types.FETCH_API_KEYS_SUCCESS,
  payload: list,
});
export const FetchAPIKeysFailed = (error) => ({
  type: types.FETCH_API_KEYS_FAILED,
  payload: error,
});

//
// ─── APIKey Status Change ACTIONS ────────────────────────────────────────────────
//

export const APIKeysEnable = (data) => ({
  type: types.CHANGE_STATUS,
  payload: data,
});
export const APIKeysEnableSuccess = (list) => ({
  type: types.CHANGE_STATUS_SUCCESS,
  payload: list,
});
export const APIKeysEnableFailed = (error) => ({
  type: types.CHANGE_STATUS_FAILED,
  payload: error,
});

//
// ─── APIKey Disable Status Change ACTIONS ────────────────────────────────────────────────
//

export const APIKeysDisable = (data) => ({
  type: types.DISABLE_STATUS,
  payload: data,
});
export const APIKeysDisableSuccess = (list) => ({
  type: types.DISABLE_STATUS_SUCCESS,
  payload: list,
});
export const APIKeysDisableFailed = (error) => ({
  type: types.DISABLE_STATUS_FAILED,
  payload: error,
});

//
// ─── APIKey Status Change ACTIONS ────────────────────────────────────────────────
//

export const CreateApiKey = (createdBy, companyUUID) => ({
  type: types.CREATE_API_KEY,
  payload: { createdBy, companyUUID },
});
export const CreateApiKeySuccess = (list) => ({
  type: types.CREATE_API_KEY_SUCCESS,
  payload: list,
});
export const CreateApiKeyFailed = (error) => ({
  type: types.CREATE_API_KEY_FAILED,
  payload: error,
});
