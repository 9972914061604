import { Box } from "@mui/material";
import React from "react";
import SkeletonAppBar from "../SkeletonAppBar";
import SkeletonConfigDetails from "../SkeletonConfigDetails";
import SkeletonConfigSection from "../SkeletonConfigSection";
import SkeletonDetailsSection from "../SkeletonDetailsSection";
import SkeletonGridList from "../SkeletonGridList";
import SkeletonHeaderSection from "../SkeletonHeaderSection";
import SkeletonLandingProject from "../SkeletonLandingProject";
import SkeletonList from "../SkeletonList";
import SkeletonPageHeader from "../SkeletonPageHeader";
import SkeletonPlayerList from "../SkeletonPlayerList";
import SkeletonSearchSection from "../SkeletonSearchSection";
import SkeletonStepperSection from "../SkeletonStepperSection";
import SkeletonVerticalTab from "../SkeletonVerticalTab";
import SkeletonViewList from "../SkeletonViewList";

function SkeletonContainer({
  header,
  search,
  list,
  landingPage,
  gridList,
  appBar,
  stepper,
  count,
  playerList,
  collector,
  mt,
  config,
  verticalTab,
  pageHeader,
  ratesList,
}) {
  return (
    <Box>
      {pageHeader && <SkeletonPageHeader />}
      {header && <SkeletonHeaderSection />}
      {appBar && <SkeletonAppBar />}
      {config && <SkeletonConfigSection />}
      {stepper && <SkeletonStepperSection count={count} />}
      {search && <SkeletonSearchSection marginTop={mt} />}
      {list && (
        <Box sx={{ display: "flex" }}>
          <SkeletonList />
        </Box>
      )}
      {landingPage && <SkeletonLandingProject />}
      {gridList && <SkeletonGridList />}
      {playerList && <SkeletonPlayerList collector={collector} />}
      {verticalTab && (
        <Box sx={{ display: "flex" }}>
          <SkeletonVerticalTab />
          <SkeletonConfigDetails />
        </Box>
      )}
      {ratesList && <SkeletonViewList rates />}
    </Box>
  );
}

export default SkeletonContainer;
