import Axios from "axios";
import { baseApiV1, baseApiV2 } from "../../constants/Presets";

export function FetchDataDictionaryList(payload) {
  const url = `${baseApiV2}dictionaries/datapoints/list?`;
  const params = [];
  const { dataPointId } = payload;
  params.push(`dataPointId=${dataPointId}`);
  const group = payload.group || "";
  params.push(`group=${group}`);
  const order = payload.order || 0;
  params.push(`order=${order}`);
  const order_By = payload.order_By || "";
  params.push(`order_by=${order_By}`);
  if (payload.query) {
    const { query } = payload;
    params.push(`query=${encodeURIComponent(query).toString()}`);
  }
  const page = payload.page || 0;
  params.push(`page=${page}`);
  const size = payload.size || "";
  params.push(`size=${size}`);
  const filters = encodeURIComponent(JSON.stringify(payload.filters)) || null;
  params.push(`filters=${filters}`);
  const paramStr = params.join("&");
  return Axios.get(url + paramStr);
}

export function FetchADataPoint(payload) {
  const { dataPointId } = payload;
  const url = `${baseApiV2}dictionaries/datapoints/list?dataPointId=${dataPointId}`;
  return Axios.get(url);
}
export function FetchDataDictionaryVersion() {
  return Axios.get(`${baseApiV2}dictionaries`);
}
export function CreateDataDictionary(DataDictionary) {
  const type = DataDictionary.dataPointId ? "patch" : "post";
  return Axios({
    method: type,
    url: `${baseApiV2}dictionaries/datapoints`,
    data: DataDictionary,
  });
}
export function FetchADataPointsLogs(dataPointId) {
  return Axios.get(
    `${baseApiV2}dictionaries/datapoints/logs?datapointId=${dataPointId}`,
  );
}
export function CreateDataPointJiraTicket(dataPointId) {
  return Axios.post(
    `${baseApiV1}datapoint_management/collector_mapping?datapoint_id=${dataPointId}`,
  );
}
export function FetchDatapointManagementList(payload) {
  const url = `${baseApiV1}datapoint_management/list?`;
  const params = [];
  params.push(`datapoint_id=${payload.dataPointId}`);
  if (payload.query) {
    const { query } = payload;
    params.push(`query=${encodeURIComponent(query).toString()}`);
  }
  if (payload.filter) {
    const filters = encodeURIComponent(JSON.stringify(payload.filter)) || null;
    params.push(`filters=${filters}`);
  }
  const order = payload.order || 0;
  params.push(`order=${order}`);
  const order_By = payload.order_By || "";
  params.push(`order_by=${order_By}`);
  const size = payload.size || "";
  params.push(`size=${size}`);
  const paramStr = params.join("&");
  return Axios.get(url + paramStr);
}

export function UpdateDatapointManagement(payload) {
  return Axios({
    method: "patch",
    url: `${baseApiV1}datapoint_management/authorize`,
    data: payload,
  });
}
