import moment from "moment";
import {
  DAYS_UP_TO_TODAY,
  LAST_30_DAYS_DATE_FORMAT,
  LAST_7_DAY_DATE_FORMAT,
  LAST_MONTH_END_DATE_FORMAT,
  LAST_MONTH_START_DATE_FORMAT,
  MONTH_TO_DATE_FORMAT,
  TODAY_DATE_FORMAT,
  YEAR_TO_DATE_FORMAT,
} from "../Constants/Constants";

export const balanceConvertion = (balance) => {
  let newBalance = 0;
  /* istanbul ignore if */
  if (balance !== null) {
    /* istanbul ignore if */
    if (balance < 1073741824) {
      newBalance =
        balance === 0
          ? `${0} MB`
          : `${(balance / (1024 * 1024)).toFixed(2)} ` + "MB";
    }
    /* istanbul ignore if */
    if (balance >= 1073741824 && balance < 1099511627776) {
      newBalance = `${(balance / (1024 * 1024 * 1024)).toFixed(2)} ` + "GB";
    }
    /* istanbul ignore if */
    if (balance >= 1099511627776) {
      newBalance =
        `${(balance / (1024 * 1024 * 1024 * 1024)).toFixed(2)} ` + "TB";
    }
  }
  return newBalance.toString();
};
/* istanbul ignore next */
export const checkDecimalPoint = (value) =>
  Number.isInteger(value) ? value : Number(value.toFixed(2));
/* istanbul ignore next */
export const capFirstLetterCapBeforeSpace = (s) =>
  s.replace(/([A-Z])/g, " $1").replace(/^./, function (str) {
    return str.toUpperCase();
  });
/* istanbul ignore next */
export const standardtDateRange = (range) => {
  // const today = moment();
  let value;
  switch (range) {
    case "LAST_7_DAYS":
      value = {
        date: [LAST_7_DAY_DATE_FORMAT, TODAY_DATE_FORMAT],
        type: "Last 7 Days",
      };
      break;
    case "MONTH_TO_DATE":
      value = {
        date: [MONTH_TO_DATE_FORMAT, TODAY_DATE_FORMAT],
        type: "Month to Date",
      };
      break;
    case "LAST_30_DAYS":
      value = {
        date: [LAST_30_DAYS_DATE_FORMAT, TODAY_DATE_FORMAT],
        type: "Last 30 Days",
      };
      break;
    case "LAST_MONTH":
      value = {
        date: [LAST_MONTH_START_DATE_FORMAT, LAST_MONTH_END_DATE_FORMAT],
        type: "Last Month",
      };
      break;
    case "YEAR_TO_DATE":
      value = {
        date: [YEAR_TO_DATE_FORMAT, TODAY_DATE_FORMAT],
        type: "Year to Date",
      };
      break;
    default:
      break;
  }
  return value;
};
/* istanbul ignore next */
export const selectCustomDateRange = (e, name, prevData) => {
  let value;
  const startDate = e[0];
  const endDate = e[1];
  const today = moment();
  /* istanbul ignore if */
  if (today <= startDate) {
    value = {
      date: [today, null],
      type: "Custom Date",
    };
  } else if (today <= endDate) {
    value = {
      date: [startDate, today],
      type: "Custom Date",
    };
  } else if (endDate !== null && endDate < startDate) {
    value = { date: [endDate, null], type: "Custom Date" };
  } else {
    value = { date: [startDate, endDate], type: "Custom Date" };
  }
  return value;
};
/* istanbul ignore next */
export const daysUpToDate = (e, name, prevData) => {
  const isValidNumber = e.target.value.replace(/[^0-9]/g, "");
  if (isValidNumber !== " ")
    return {
      date: [
        isValidNumber === "1" || isValidNumber === "" || isValidNumber === "0"
          ? TODAY_DATE_FORMAT
          : moment().subtract(isValidNumber - 1, "days"),
        TODAY_DATE_FORMAT,
      ],
      type: `${isValidNumber} ${DAYS_UP_TO_TODAY}`,
      value: isValidNumber === "0" || isValidNumber === "" ? "" : isValidNumber,
    };
  return prevData;
};

/* istanbul ignore next */
export const capitalizeFLetter = (str) => {
  const strings = str?.split(" ");
  return strings
    ?.map((string) => string[0].toUpperCase() + string.slice(1))
    .join(" ");
};
/* istanbul ignore next */
export const changeGroupName = (str) =>
  capitalizeFLetter(str?.toLowerCase().split("_").join(" "));
/* istanbul ignore next */
export const dateFormatMMDDYYYY = (date) => moment(date).format("MM-DD-YYYY");

export const addCustomEvent = (player) => {
  /* istanbul ignore if */
  if (!player?.eventList?.length) return player;
  const eventLabels = ["Custom Event", "Dolby Millicast Events All"];
  player.eventList = player.eventList.filter((eventObj) => {
    /* istanbul ignore if */
    if (eventLabels.includes(eventObj.label)) {
      eventObj.group =
        eventObj.label === "Custom Event" ? "Custom Data" : "Dolby Millicast";
      player.metaDataList.push(eventObj);
      return false;
    }
    return true;
  });
  return player;
};

export const updateFilterName = (filter) => ({
  ...filter,
  adFrameWork: filter?.adFrameWork?.map((item) =>
    item === "Yospace" ? "YoSpace" : item,
  ),
});
