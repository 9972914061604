import { Box, Skeleton } from "@mui/material";
import React from "react";

const styles = {
  box_container: {
    width: "380px",
    borderRight: "1px solid rgba(0,0,0,0.12)",
    bgcolor: "#FFFFFF",
    height: "calc(100vh - 240px)",
    letterSpacing: "0.15px",
  },
};
function SkeletonVerticalTab() {
  return (
    <Box sx={styles.box_container}>
      <Box sx={{ p: "30px 16px 9px 40px" }}>
        <Skeleton variant="rectangular" />
      </Box>
    </Box>
  );
}

export default SkeletonVerticalTab;
