import { Box, Skeleton } from "@mui/material";
import React from "react";

const styles = {
  subheader_box: {
    backgroundColor: "#ffffff",
    width: "100%",
    pt: "70px",
    pl: "24px",
    pr: "32px",
    borderBottom: "1px solid rgba(0,0,0,0.11)",
  },
};

function SkeletonHeaderSection() {
  /* istanbul ignore next */
  return (
    <Box>
      <Box sx={styles.subheader_box}>
        <Box sx={{ display: "flex" }}>
          <Skeleton variant="text" width="20px" />
          <Skeleton variant="text" width="225px" sx={{ ml: "10px" }} />
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            width: "auto",
            alignItems: "center",
            mb: "17px",
            mt: "8px",
          }}
        >
          <Skeleton variant="text" width="200px" height="32px" />{" "}
          <Skeleton
            variant="circular"
            width="180px"
            height="34px"
            sx={{ borderRadius: "24px" }}
          />{" "}
        </Box>
      </Box>
    </Box>
  );
}

export default SkeletonHeaderSection;
