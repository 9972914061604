import { Box, Skeleton, Typography } from "@mui/material";
import React from "react";

function SkeletonList() {
  return (
    <Box
      sx={{
        width: "367px",
        borderRight: "1px solid rgba(0,0,0,0.11)",
        bgcolor: "#FFFFFF",
        overflowY: "scroll",
        overflowX: "hidden",
        height: "calc(100vh - 240px)",
        letterSpacing: "0.15px",
        "&::-webkit-scrollbar-thumb": {
          borderRadius: "100px",
          border: "3px solid transparent",
          backgroundClip: "content-box",
          height: "58px",
        },
        "&::-webkit-scrollbar": {
          width: "12px",
        },
        "&::-webkit-scrollbar-track-piece": {
          borderLeft: "1px solid rgba(0,0,0,0.11)",
        },
      }}
    >
      <Box sx={{ pt: "20px", pr: "24px", pl: "24px" }}>
        {Array.from(new Array(100)).map(
          /* istanbul ignore next */ (e, i) => (
            <Skeleton
              key={i}
              variant="text"
              width="310px"
              height="28px"
              sx={{ mb: "10px" }}
            />
          ),
        )}
      </Box>
    </Box>
  );
}

export default SkeletonList;
