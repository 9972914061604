import CacheBuster from "react-cache-buster";
import { LicenseInfo } from "@mui/x-license-pro";
import Main from "../app-old/main/Main";
import Loader from "../app-old/common/Loader";
import packageInfo from "../../package.json";

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_KEY);

function App() {
  return (
    <div className="App">
      <CacheBuster
        currentVersion={packageInfo.version}
        isEnabled
        isVerboseMode={false}
        loadingComponent={<Loader />}
      >
        <Main />
      </CacheBuster>
    </div>
  );
}

export default App;
